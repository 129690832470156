import { React } from "react";
import { Button, Container, Row, Col, Nav } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function SessionHeaderLoader() {
  return (
    <>
      <div className="bg-light py-5">
        <Container>
          <Row className="gy-6">
            <Col lg={6}>
              <h1 className="text-primary text-futura-pt">
                <Skeleton />
              </h1>
              <p className="text-futura-pt-book text-lg">
                <Skeleton />
                <Skeleton />
              </p>
              <div className="hstack flex-wrap gap-4 mb-4">
                <p className="text-danger hstack">
                  <Skeleton width={250} />
                </p>
                <p className="text-danger hstack">
                  <Skeleton width={250} />
                </p>
              </div>
              <Button disabled variant="secondary" className="text-futura-pt">
                <Skeleton width={130} baseColor="secondary" />
              </Button>
            </Col>
            <Col lg={6} className="vstack gap-4">
              <Nav variant="tabs" className="justify-content-lg-end">
                {Array.from({ length: 4 }).map((_, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index}>
                      <Skeleton width={45} />
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default SessionHeaderLoader;
