import { React } from "react";
import { Modal } from "react-bootstrap";

function JotFormComponent({ show, hide }) {
  const iframeStyles = {
    width: "100%",
    height: "100%",
    minHeight: "500px",
  };

  return (
    <Modal show={show} onHide={hide} size="xl" centered>
      <Modal.Header className="bg-secondary" closeButton>
        <Modal.Title>Purchase Seats</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0 ">
        <div>
          <iframe
            title="JotForm"
            srcDoc={`<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
            </head>
            <body>
              
              <script type="text/javascript" src="https://form.jotform.com/jsform/232355613774055"></script>
            </body>
          </html>`}
            style={iframeStyles}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default JotFormComponent;
