import { React } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { HeaderLoader } from "./index";

function SessionCardLoader() {
  return (
    <>
      <HeaderLoader />
      <Container className="mt-8">
        <p className="text-lg mb-10 text-raven">
          <Skeleton />
        </p>
        <Row className="mb-8">
          {Array.from({ length: 3 }).map((_, index) => (
            <Col lg={4} md={6} key={index}>
              <Card>
                <Skeleton
                  height={200}
                  width="100%"
                  style={{ marginBottom: "1rem" }}
                />
                <Card.Body>
                  <Card.Title className="mb-0 text-futura text-xl font-semibold text-primary">
                    <Skeleton />
                  </Card.Title>
                  <Card.Text className="text-sm">
                    <Skeleton />
                  </Card.Text>
                  <Card.Text className="text-sm">
                    <Skeleton />
                  </Card.Text>
                  <Card.Text className="text-sm mb-2">
                    <Skeleton />
                  </Card.Text>
                  <Card.Text className="text-sm">
                    <Skeleton />
                  </Card.Text>
                  <Card.Text className="text-sm mb-2">
                    <Skeleton />
                  </Card.Text>
                  <div className="w-full btn btn-outline-primary">
                    <Skeleton />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
export default SessionCardLoader;
