import { React } from "react";
import { Table, Form, Button, Image } from "react-bootstrap";
import { CriticalCare, Emergancy, StepDown, Surgery } from "@/assets/images";
import {
  defaultAmbulanceDiversionsCost,
  defaultAmbulanceDiversionsError,
  defaultArrivalsWaitingCost,
  defaultArrivalsWaitingError,
  defaultExtraStaffCost,
  defaultExtraStaffError,
  defaultHoursNotComplete,
  defaultHoursNotCompleteCost,
  defaultPatientsWaitingCost,
  defaultPatientsWaitingError,
  defaultRequestWaitingError,
} from "@/utils/activity";
import { constant, table } from "@/utils/constant";

function TeamPerformanceTable({
  gameData,
  singleActivityCount,
  name,
  setActiveKey,
}) {
  const Financial = name === "Cost";
  const handleEventKey = () => {
    if (Financial) {
      setActiveKey("third");
    } else {
      setActiveKey("second");
    }
  };
  const emergancyRoom = [
    {
      label: constant?.ambulanceDiversions,
      key: "ambulanceDiversions",
      cost: Financial
        ? defaultAmbulanceDiversionsCost
        : defaultAmbulanceDiversionsError,
    },
    {
      label: constant?.patientsInWaiting,
      key: "patientsWaiting",
      cost: Financial
        ? defaultPatientsWaitingCost
        : defaultPatientsWaitingError,
    },
    {
      label: constant?.extraStaff,
      key: "extraStaff",
      cost: Financial ? defaultExtraStaffCost : defaultExtraStaffError,
    },
  ];

  const otherRooms = [
    {
      label: constant?.arrivalsWaiting,
      key: "arrivalsWaiting",
      cost: Financial
        ? defaultArrivalsWaitingCost
        : defaultArrivalsWaitingError,
    },
    ...(!Financial
      ? [
          {
            label: constant?.requestsWaiting,
            key: "requestsWaiting",
            cost: defaultRequestWaitingError,
          },
          {
            label: constant?.extraStaff,
            key: "extraStaff",
            cost: Financial ? defaultExtraStaffCost : defaultExtraStaffError,
          },
        ]
      : [
          {
            label: constant?.extraStaff,
            key: "extraStaff",
            cost: Financial ? defaultExtraStaffCost : defaultExtraStaffError,
          },
        ]),
  ];

  return (
    <>
      <Table
        borderless
        responsive
        size="sm"
        className="bg-transparent teamperformance-table w-max mx-auto"
      >
        <thead>
          <tr>
            <th className="pt-0 text-end title">
              <Image src={Emergancy} fluid className="me-2 mt-n1" />
              {constant?.emergency}
            </th>
            <th className="w-sm pt-0 px-0">{constant?.totalPaperwork}</th>
            <th className="w-sm pt-0 px-0">
              {Financial ? "Unit Cost" : "Quality Loss Weighting Factor"}
            </th>
            <th className="w-xs pt-0 px-0"></th>
            <th className="w-sm pt-0 px-0">
              {Financial ? "Excess Cost" : "Quality Loss"}
            </th>
          </tr>
        </thead>
        <tbody>
          {emergancyRoom?.map((item, index) => (
            <tr key={index}>
              <td className="py-0 text-end">{item?.label}</td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  defaultValue={singleActivityCount?.emergencyRoom?.[item.key]}
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                />
              </td>
              <td className="p-0 border border-black">
                <Form.Control
                  className="rounded-0 border-0 bg-white-smoke text-center text-black"
                  disabled
                  readOnly
                  type="text"
                  defaultValue={`x ${item?.cost.toLocaleString("en-en")}`}
                />
              </td>
              <td className="text-center p-0"> = </td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={(
                    item.cost * singleActivityCount?.emergencyRoom?.[item?.key]
                  ).toLocaleString("en-en")}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <thead>
          <tr>
            <th className="pt-8 text-end title">
              <Image src={Surgery} fluid className="me-2 mt-n1" />
              {constant?.surgery}
            </th>
            <th className="w-sm pt-16 px-0"></th>
            <th className="w-sm pt-16 px-0"></th>
            <th className="w-xs pt-8 px-0"></th>
            <th className="w-sm pt-8 px-0"></th>
          </tr>
        </thead>
        <tbody>
          {otherRooms?.map((item, index) => (
            <tr key={index}>
              <td className="py-0 text-end">{item?.label}</td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={singleActivityCount?.surgeryRoom?.[item.key]}
                />
              </td>
              <td className="p-0 border border-black">
                <Form.Control
                  className="rounded-0 border-0 bg-white-smoke text-center text-black text-center"
                  disabled
                  readOnly
                  type="text"
                  defaultValue={`x ${item?.cost.toLocaleString("en-en")}`}
                />
              </td>
              <td className="text-center p-0"> = </td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={(
                    item?.cost * singleActivityCount?.surgeryRoom?.[item?.key]
                  ).toLocaleString("en-en")}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <thead>
          <tr>
            <th className="pt-8 text-end title">
              <Image src={CriticalCare} fluid className="me-2 mt-n1" />
              {constant?.criticalCare}
            </th>
            <th className="w-sm pt-8 px-0"></th>
            <th className="w-sm pt-8 px-0"></th>
            <th className="w-xs pt-8 px-0"></th>
            <th className="w-sm pt-8 px-0"></th>
          </tr>
        </thead>
        <tbody>
          {otherRooms?.map((item, index) => (
            <tr key={index}>
              <td className="py-0 text-end">{item?.label}</td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={singleActivityCount?.criticalCare?.[item.key]}
                />
              </td>
              <td className="p-0 border border-black">
                <Form.Control
                  className="rounded-0 border-0 bg-white-smoke text-center text-black text-center"
                  disabled
                  readOnly
                  type="text"
                  defaultValue={`x ${item?.cost.toLocaleString("en-en")}`}
                />
              </td>
              <td className="text-center p-0"> = </td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={(
                    item?.cost * singleActivityCount?.criticalCare?.[item?.key]
                  ).toLocaleString("en-en")}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <thead>
          <tr>
            <th className="pt-8 text-end title">
              <Image src={StepDown} fluid className="me-2 mt-n1" />
              {constant?.stepDown}
            </th>
            <th className="w-sm pt-8 px-0"></th>
            <th className="w-sm pt-8 px-0"></th>
            <th className="w-xs pt-8 px-0"></th>
            <th className="w-sm pt-8 px-0"></th>
          </tr>
        </thead>
        <tbody>
          {otherRooms?.map((item, index) => (
            <tr key={index}>
              <td className="py-0 text-end">{item?.label}</td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0  border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={singleActivityCount?.stepDownUnit?.[item.key]}
                />
              </td>
              <td className="p-0 border border-black">
                <Form.Control
                  className="rounded-0 border-0 ms-n07 bg-white-smoke text-center text-black text-center"
                  disabled
                  readOnly
                  type="text"
                  defaultValue={`x ${item?.cost.toLocaleString("en-en")}`}
                />
              </td>
              <td className="text-center p-0"> = </td>
              <td className="p-0 border border-black">
                <Form.Control
                  readOnly
                  className="rounded-0 border-0 text-center"
                  type="text"
                  placeholder=""
                  defaultValue={(
                    item?.cost * singleActivityCount?.stepDownUnit?.[item?.key]
                  ).toLocaleString("en-en")}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <thead>
          <tr>
            <th className="pt-8 text-end title"></th>
            <th className="w-sm pt-8 px-0 text-inherit">
              {table?.numberOfHoursNotCompleted}
            </th>
            <th className="w-sm pt-8 px-0"></th>
            <th className="w-xs pt-8 px-0"></th>
            <th className="w-sm pt-8 px-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-0 text-end">
              {table?.ifYouDidNot} <br /> {table?.completeHours}
            </td>
            <td className="p-0 border border-black">
              <Form.Control
                readOnly
                className="rounded-0 border-0 text-center"
                type="text"
                placeholder=""
                defaultValue={singleActivityCount?.noOfHoursNotComplete}
              />
            </td>
            <td className="p-0 border border-black bg-white-smoke">
              <Form.Control
                className="rounded-0 border-0  text-center text-black h-full"
                disabled
                readOnly
                type="text"
                defaultValue={`x ${
                  Financial
                    ? defaultHoursNotCompleteCost.toLocaleString("en-en")
                    : defaultHoursNotComplete
                }`}
              />
            </td>
            <td className="text-center p-0"> = </td>
            <td className="p-0 border border-black">
              <Form.Control
                readOnly
                className="rounded-0 border-0 text-center"
                type="text"
                placeholder=""
                defaultValue={(
                  singleActivityCount?.noOfHoursNotComplete *
                  (Financial
                    ? defaultHoursNotCompleteCost
                    : defaultHoursNotComplete)
                ).toLocaleString("en-en")}
              />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan={5} className="p-0">
              <div className="hstack gap-6 mt-10 justify-content-end ms-auto">
                <p className="text-15 font-bold text-uppercase">
                  {Financial
                    ? table?.financialtotalCost
                    : table?.qualitytotalCost}
                </p>
                <Form.Control
                  readOnly
                  className="rounded-0 border-black text-center"
                  type="text"
                  placeholder=""
                  defaultValue={
                    Financial
                      ? `$${gameData?.teamCost.toLocaleString("en-en")}`
                      : gameData?.teamQualityError.toLocaleString("en-en")
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td
              onClick={handleEventKey}
              colSpan={5}
              className="p-0 text-center"
            >
              <div className="hstack gap-6 mt-10 justify-content-center">
                <Button variant="secondary" className="px-32">
                  {table?.next}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
export default TeamPerformanceTable;
