import React, { useState } from "react";
import { SessionModal, SessionCard } from "@/components";
import { Container, Row, Col, Card, Button, Pagination } from "react-bootstrap";
import CommonLayout from "./commonLayout";
import { SessionCardLoader } from "@/components/loder/index";
import { constant } from "@/utils/constant";
import JotFormComponent from "@/components/jotzform";

const Session = ({
  isAscending,
  handleAscending,
  handleShow,
  data,
  isLoading,
  show,
  refetch,
  currentPage,
  totalPages,
  handlePageChange,
  user_data,
}) => {
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const purchaseModal = () => {
    setShowPurchaseModal(!showPurchaseModal);
  };

  const renderPurchaseMessage = () => {
    const message =
      data?.session?.length >= 1
        ? "Schedule or manage sessions below."
        : "Your organization has no upcoming sessions. Schedule a new session below.";

    return (
      <p className="mb-10 text-raven text-15">
        {isAscending ? message : ""}
        {/* <span
          onClick={purchaseModal}
          className="text-underline text-raven ms-1 cursor-pointer"
        >
          purchase seats for future sessions
        </span>
        . */}
      </p>
    );
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 10;
    const ellipsis = <Pagination.Ellipsis />;
    const keyPrefix = "page";
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
    if (startPage > 1) {
      pages.push(
        React.cloneElement(ellipsis, { key: `${keyPrefix}_ellipsis_start` })
      );
    }
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <Pagination.Item
          key={`${keyPrefix}_${page}`}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }
    if (endPage < totalPages) {
      pages.push(
        React.cloneElement(ellipsis, { key: `${keyPrefix}_ellipsis_end` })
      );
      pages.push(
        <Pagination.Item
          key={`${keyPrefix}_${totalPages}`}
          active={totalPages === currentPage}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pages;
  };

  return (
    <>
      {isLoading || data?.session === null ? (
        <SessionCardLoader />
      ) : (
        <CommonLayout
          isAscending={isAscending}
          handleAscending={handleAscending}
          handleShow={handleShow}
        >
          <Container className="mt-8">
            {([3, 4].includes(user_data?.user?.role_id)) && (
              renderPurchaseMessage()
            )}
            <Row className="mb-8 gy-7 gx-md-20">
              {isAscending && (
                <Col lg={4} md={6}>
                  <Card bg="transparent" className="session-card h-full">
                    <Card.Body className="hstack mx-auto">
                      <Button
                        variant="secondary"
                        onClick={handleShow}
                        className="font-semibold"
                      >
                        {constant?.scheduleSession}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              )}
              {data?.session?.map((item, index) => (
                <Col lg={4} md={6} key={index}>
                  <SessionCard data={item} />
                </Col>
              ))}
              {totalPages ? (
                <Pagination className="mb-8">
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                  />
                  {renderPageNumbers()}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                  />
                </Pagination>
              ) : (
                <></>
              )}
            </Row>
          </Container>
        </CommonLayout>
      )}
      {show && (
        <SessionModal show={show} hide={() => handleShow()} refetch={refetch} />
      )}
      {showPurchaseModal && (
        <JotFormComponent
          show={showPurchaseModal}
          hide={() => purchaseModal()}
        />
      )}
    </>
  );
};
export default Session;
