import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { constant, modal } from "@/utils/constant";

function ChallengeModal({ hide, show }) {
  const roleName = ["surgery", "emergency", "stepDown", "criticalCare"];
  return (
    <>
      <Modal show={show} onHide={hide} size="md" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.challengeModalTital}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p className="mb-3">{modal?.challengeModalText}</p>
          <table>
            <tbody>
              {roleName?.map((step, index) => (
                <tr key={index}>
                  <td>{constant?.[step]}</td>
                  <td>
                    <BsArrowRight className="mx-5" />
                  </td>
                  <td>{constant?.[index === 3 ? "surgery" : step]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Row className="mt-8">
            <Col xs="7">
              <Button variant="secondary" className="font-semibold w-full">
                {modal?.challengeModalIssueButtonText}
              </Button>
            </Col>
            <Col xs="5">
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
              >
                {modal?.cancle}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ChallengeModal;
