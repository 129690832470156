import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function HeaderLoader() {
  return (
    <Navbar variant="dark" bg="primary" className="py-0">
      <Container>
        <Navbar.Brand>
          <p>
            <Skeleton width={142} height={32} />
          </p>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ms-auto gap-5">
            <Skeleton width={142} height={32} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderLoader;
