import { supabase } from "../config/supabase";
import moment from "moment";
const statusCode = [200, 201, 204];

export const useFetchSession = async (sessionData) => {
  try {
    if (!sessionData?.id) return;
    const currentDate = new Date().toLocaleString("en-US").split(", ")[0];
    const PAGE_SIZE = process.env.REACT_APP_SESSION_TABLE_PAGE;
    const query = supabase
      .from("session")
      .select(
        "id, name, session_date, no_of_participant, amount, payment_method, is_open_game_link, team_participant (*), facilitator (id, uuid, first_name, last_name, email), co_facilitator (id, uuid, first_name, last_name, email), user_id (id, uuid, first_name, last_name, email), organization_id, is_game_complete, organization_sheet, payment_link, selected_sheet, is_game_pause"
      )
      .eq("organization_id", sessionData.id)
      .is("cancel_session", null);

    if (!sessionData?.isAscending) {
      // past
      query.lte("session_date", currentDate);
      query.eq("is_game_complete", 1);
      query.order("id", { ascending: false });
    }
    if (sessionData?.isAscending) {
      // upcoming
      query.gte("session_date", currentDate);
      query.eq("is_game_complete", 0);
    }
    query.order("session_date", { ascending: sessionData?.isAscending });
    const { data: totalData } = await query;
    query.range(
      sessionData.currentPage * PAGE_SIZE - PAGE_SIZE,
      sessionData?.isAscending
        ? sessionData.currentPage * PAGE_SIZE - 1
        : sessionData.currentPage * PAGE_SIZE
    );

    const { data } = await query;
    const totalPages = Math.ceil(totalData.length / PAGE_SIZE);
    return { session: data, totalPages };
  } catch (error) {}
};
export const useFetchSingleSession = async (id) => {
  try {
    if (!id) return;
    return supabase
      .from("session")
      .select(
        "id, name, session_date, orientation_video_start, start_date, no_of_participant, amount, payment_method, is_open_game_link, is_game_complete, facilitator (id, uuid, first_name, last_name, email), co_facilitator (id, uuid, first_name, last_name, email), user_id (id, uuid, first_name, last_name, email), organization_id, organization_sheet, payment_link, selected_sheet, is_game_pause"
      )
      .eq("id", id)
      .then(async (item) => {
        if (statusCode.includes(item.status)) {
          const { data } = await supabase
            .from("team_participant")
            .select()
            .eq("session_id", id);

          return {
            session: item.data[0] || {},
            participant: data,
          };
        }
      });
  } catch (error) {}
};

export const useStartGameUpdateStatus = async (id) => {
  try {
    const momentNow = moment().add(3, 'seconds');
    if (!id) return;
    const { data, error } = await supabase
      .from("session")
      .update({ start_date: momentNow, time_zone: id.timeZone  })
      .match({ id: id.session_id });
    if (error) {
      return error;
    }

    const { error: partError } = await supabase
      .from("team")
      .update({ current_status: 3 })
      .match({ session_id: id.session_id });
    if (partError) {
      return error;
    }

    const { error: teamPartError } = await supabase
      .from("team_participant")
      .update({ current_status: 3 })
      .match({ session_id: id.session_id });
    if (teamPartError) {
      return error;
    }

    return data;
  } catch (error) {}
};

export const useUpdateSessionData = async (sessionData) => {
  try {
    const { data: sess, error: sessionGetError } = await supabase
      .from("session")
      .select("*")
      .eq("id", sessionData?.id);
    if (sessionGetError) {
      return;
    }
    const increase_no_of_participant =
      Number(sessionData.no_of_participant) - sess[0]?.no_of_participant;
    const decrease_no_of_participant =
      sess[0]?.no_of_participant - Number(sessionData.no_of_participant);

    const { data: team, error: teamGetError } = await supabase
      .from("team")
      .select()
      .eq("session_id", sessionData?.id)
      .order("team_index", { ascending: true });
    if (teamGetError) {
      return;
    }
    if (increase_no_of_participant > 0) {
      const loop = Math.floor(sessionData?.no_of_participant / 4);
      const needToAdd = loop - team.length;
      // inserts for teams
      const teamInsertPromises = [];

      for (let index = 1; index < needToAdd + 1; index++) {
        teamInsertPromises.push({
          session_id: sessionData?.id,
          team_index: team.length + index,
        });
      }
      // insert teams
      const { data: teamInsertResults } = await supabase
        .from("team")
        .insert(teamInsertPromises)
        .select("id");

      // inserts for participant
      const participantInsertPromises = [];
      for (let index = 0; index < teamInsertResults.length; index++) {
        const team_id = teamInsertResults[index].id;
        for (let i = 0; i < 4; i++) {
          participantInsertPromises.push({
            role_id: i + 1,
            session_id: sessionData?.id,
            team_id,
          });
        }
      }
      // // insert participants
      await Promise.all(participantInsertPromises);
      await supabase.from("team_participant").insert(participantInsertPromises);
    }
    if (decrease_no_of_participant > 0) {
      const loop = Math.floor(sessionData?.no_of_participant / 4);
      const needToRemove = team.length - loop;
      const teamsToRemove =
        needToRemove > 0 ? team.slice(-needToRemove).reverse() : [];
      const deleteOperations = teamsToRemove.map(async (element) => {
        const { error: teamPartiError } = await supabase
          .from("team_participant")
          .delete()
          .match({
            session_id: sessionData?.id,
            team_id: element.id,
          });
        if (teamPartiError) {
          return Promise.reject(teamPartiError);
        }
        const { error: teamError } = await supabase
          .from("team")
          .delete()
          .match({
            session_id: sessionData?.id,
            id: element.id,
          });
        if (teamError) {
          return Promise.reject(teamError);
        }
        return Promise.resolve();
      });
      if (Number(sessionData.no_of_participant) % 4 >= 0) {
				const { data: team_participant_data } = await supabase
					.from('team_participant')
					.select()
					.order('id', { ascending: false })
					.eq('session_id', sessionData?.id)
					.in('role_id', [5, 6, 7, 8]);

				team_participant_data &&
					team_participant_data.map(async (item, index) => {
						if (
							Number(sessionData.no_of_participant) % 4 > index ||
							Number(sessionData.no_of_participant) % 4 === 0
						) {
							const { error: teamPartiError } = await supabase
								.from('team_participant')
								.delete()
                .eq("id", item.id);
						}
					});
			}
      try {
        await Promise.all(deleteOperations);
      } catch (error) {}
    }
    const { data: session, error: sessionError } = await supabase
      .from("session")
      .update({
        ...sessionData,
      })
      .eq("id", sessionData?.id)
      .select();
    if (sessionError) {
      return;
    }
    return { session: session };
  } catch (error) {}
};

export const useCancelSession = async (data) => {
  try {
    if (!data.id) return;
    const { data: session, error } = await supabase
      .from("session")
      .update({ cancel_session: new Date() })
      .match({ id: data.id });
    await supabase
      .from("organization")
      .update({ available_seat: data?.total_sheet })
      .match({ id: data.organization_id });
    if (error) {
      return error;
    }
    return session;
  } catch (error) {}
};
export const useAddSession = async (sessionData) => {
  const userId = localStorage.getItem("userId");
  try {
    let selected_sheet = 0;
    let organization_sheet = 0;
    let session_organization_sheet = 0;

    if (sessionData?.is_check_mark) {
      const { data: org } = await supabase
        .from("organization")
        .select()
        .eq("id", sessionData?.organization_id);

      const available_seat = org[0]?.available_seat;
      const no_of_participant = sessionData?.no_of_participant;

      if (available_seat >= no_of_participant) {
        const total_seat = available_seat - no_of_participant;
        selected_sheet = 0;
        organization_sheet = total_seat;
        session_organization_sheet = no_of_participant;
      } else {
        const total_seat = no_of_participant - available_seat;
        selected_sheet = total_seat;
        organization_sheet = 0;
        session_organization_sheet = available_seat;
      }

      // Update organization's available_seat
      await supabase
        .from("organization")
        .update({
          available_seat: organization_sheet,
        })
        .eq("id", sessionData?.organization_id);
    } else {
      selected_sheet = sessionData?.no_of_participant;
    }

    // Create session
    delete sessionData?.is_check_mark;
    const { data: session, error: sessionError } = await supabase
      .from("session")
      .insert({
        ...sessionData,
        user_id: userId,
        selected_sheet,
        organization_sheet: session_organization_sheet,
      })
      .select();
    if (sessionError) {
      return;
    }
    if (session.length > 0) {
      const loop = Math.floor(sessionData?.no_of_participant / 4);

      // inserts for teams
      const teamInsertPromises = [];

      for (let index = 1; index < loop + 1; index++) {
        teamInsertPromises.push({
          session_id: session[0].id,
          team_index: index,
        });
      }
      // insert teams
      const { data: teamInsertResults } = await supabase
        .from("team")
        .insert(teamInsertPromises)
        .select("id");

      // inserts for participant
      const participantInsertPromises = [];
      for (let index = 0; index < teamInsertResults.length; index++) {
        const team_id = teamInsertResults[index].id;
        for (let i = 0; i < 4; i++) {
          participantInsertPromises.push({
            role_id: i + 1,
            session_id: session[0].id,
            team_id,
          });
        }
      }
      // insert participants
      await Promise.all(participantInsertPromises);
      await supabase.from("team_participant").insert(participantInsertPromises);
    }
    return { session: session };
  } catch (error) {}
};

export const updateSession = async () => {
  try {
    const { data } = await supabase
      .from("session")
      .select("id", { column: "array_agg" })
      .lt("session_date", moment(new Date()).format("YYYY-MM-DD"))
      .eq("is_game_complete", 0);

    const promises = data.map(async (update) => {
      await supabase
        .from("session")
        .update({ is_game_complete: 1 })
        .eq("id", update.id);
    });

    // Wait for all updates to complete
    await Promise.all(promises);
    return data;
  } catch (error) {
    console.log("error", error);
  }
};
