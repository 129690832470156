import React from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ParticipantsTableLoader() {
  const renderSkeletonRow = (index) => (
    <tr key={index}>
      {Array.from({ length: 6 }).map((_, index) => (
        <td key={index}>
          <Skeleton width={100} />
        </td>
      ))}
    </tr>
  );

  return (
    <>
      <Table className="mb-10" bordered responsive>
        <tbody>
          {Array.from({ length: 6 }).map((_, index) =>
            renderSkeletonRow(index)
          )}
        </tbody>
      </Table>
    </>
  );
}
export default ParticipantsTableLoader;
