import { useQuery, useMutation } from "react-query";
import * as teamApi from "@/api/index";

export function useGetTeams(id) {
  return useQuery(["sessions", [id]], () => teamApi.useFetchTeam(id));
}
export function useFetchTeamParticipant(id) {
  return useQuery(["teamParticipant", [id]], () =>
    teamApi.useFetchTeamParticipant(id)
  );
}
export function useUpdateTeam() {
  return useMutation(teamApi.usePatchTeam);
}
export function useUpdateAddParticipate() {
  return useMutation(teamApi.usePatchAddParticipate);
}
export function useRemoveTeamParticipant() {
  return useMutation(teamApi.useDeleteTeamParticipant);
}
export function useUpdateGameParticipant() {
  return useMutation(teamApi.usePatchGameParticipant);
}
export function useGetRoles() {
  return useQuery("roles", teamApi.useFetchRole);
}
export function useCoManagerData() {
  return useMutation(teamApi.addCoManager);
}
export function useRemoveCoRoles() {
  return useMutation(teamApi.RemoveCoRoles);
}

