import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { BsQuestionCircle, BsX } from "react-icons/bs";
import { Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { decrypt, encrypt } from "../utils/crypto";
import { useNavigate, useParams } from "react-router-dom";
import TeamPerformanceModal from "./teamPerformanceModal";
import {
  blendedTeamCost,
  blendedTeamQualityError,
  defaultAmbulanceDiversionsCost,
  defaultAmbulanceDiversionsError,
  defaultArrivalsWaitingCost,
  defaultArrivalsWaitingError,
  defaultExtraStaffCost,
  defaultExtraStaffError,
  defaultHoursNotComplete,
  defaultHoursNotCompleteCost,
  defaultPatientsWaitingCost,
  defaultPatientsWaitingError,
  defaultRequestWaitingError,
  defaultRounds,
} from "@/utils/activity";
import { useGetSingleSessions } from "@/customHooks";
import moment from "moment";
import TeamPerformanceLoder from "@/components/loder/teamParformLoder";
import { useFetchGameData } from "@/customHooks/useGame";
import { constant } from "@/utils/constant";

const TeamPerformance = ({ isLoggedIn }) => {
  const param = useParams();
  const [performModal, setPerformModal] = useState(false);
  const getSession = useGetSingleSessions(Number(decrypt(param.id)));
  const sessionData = getSession?.data || {};
  const session = sessionData?.session || {};
  const sessionDate = session && session?.session_date

  const navigate = useNavigate();
  const [gameData, setGameData] = useState();
  const [allTeam, setAllTeams] = useState([]);
  const [teamName, setTeamName] = useState();
  const [transformedArray, setTransformedArray] = useState([]);
  const [singleActivityCount, setSingleActivityCount] = useState();
  const gameInfo = useFetchGameData(Number(decrypt(param.id)));
  // State to manage button selection and its value range
  const [buttonArray, setbuttonArray] = useState([
    { text: "First-time", selected: true, value: [5, 12] },
    { text: "Experienced", selected: false, value: [1, 3.5] },
  ]);

  const countQualityErrors = (
    gameData,
    current_round,
    game_complete_status
  ) => {
    // ACTIVITY
    let ambulanceDiversionsActivity = 0;
    let patientsWaitingActivity = 0;
    let arrivalsWaitingActivity = 0;
    let requestWaitingActivity = 0;
    let extraStaffActivity = 0;

    if (!gameData) {
      return {
        activity: {
          ambulanceDiversionsActivity,
          patientsWaitingActivity,
          arrivalsWaitingActivity,
          requestWaitingActivity,
          extraStaffActivity,
        },
        teamCost: 0,
        teamQualityError: 0,
      };
    }
    // QUALITY ERRORS
    let ambulanceDiversions = 0;
    let patientsWaiting = 0;
    let arrivalsWaiting = 0;
    let requestWaiting = 0;
    let extraStaff = 0;
    let noOfHoursNotComplete = 0;

    // COST
    let ambulanceDiversionsCost = 0;
    let patientsWaitingCost = 0;
    let arrivalsWaitingCost = 0;
    let extraStaffCost = 0;
    let noOfHoursNotCompleteCost = 0;
    for (const data of gameData) {
      // ACTIVITY
      const totalRequestWaiting = [
        "coming_from_emergency",
        "coming_from_critical",
        "coming_from_surgery",
      ].reduce(
        (sum, key) =>
          sum +
          (data?.[key]?.filter((waiting) => waiting.status === 1)
            ?.length || 0),
        0
      );
      ambulanceDiversionsActivity +=
        Number(data?.ambulance_diversions_count) || 0;
      patientsWaitingActivity += Number(data?.patients_waiting_count) || 0;
      arrivalsWaitingActivity +=
        (data?.role_id !== 1 && Number(data?.data?.walking?.patient_count)) ||
        0;
      requestWaitingActivity += Number(totalRequestWaiting) || 0;
      extraStaffActivity += Number(data.total_extra_staff) || 0;

      if (data?.role_id === 1) {
        // QUALITY ERRORS
        ambulanceDiversions +=
          Number(data?.ambulance_diversions_count) *
            defaultAmbulanceDiversionsError || 0;
        patientsWaiting +=
          Number(data?.patients_waiting_count) * defaultPatientsWaitingError ||
          0;
        // COST
        ambulanceDiversionsCost +=
          Number(data?.ambulance_diversions_count) *
            defaultAmbulanceDiversionsCost || 0;
        patientsWaitingCost +=
          Number(data?.patients_waiting_count) * defaultPatientsWaitingCost ||
          0;
      } else {
        // QUALITY ERRORS
        arrivalsWaiting +=
          (data?.role_id !== 1 && Number(data?.data?.walking?.patient_count)) *
          defaultArrivalsWaitingError;
        requestWaiting +=
          Number(totalRequestWaiting) * defaultRequestWaitingError;
        // COST
        arrivalsWaitingCost +=
          (data?.role_id !== 1 && Number(data?.data?.walking?.patient_count)) *
          defaultArrivalsWaitingCost;
      }
      extraStaffCost += Number(data.total_extra_staff) * defaultExtraStaffCost;
      extraStaff += Number(data.total_extra_staff) * defaultExtraStaffError;
    }

    if (current_round > 1) {
      // if rounds is 24 then no need calculation
      let notCompletedRounds;
      if (
        defaultRounds === current_round &&
        game_complete_status === "times-complete"
      ) {
        notCompletedRounds = 1;
      } else {
        notCompletedRounds = defaultRounds - current_round;
      }
      noOfHoursNotComplete += notCompletedRounds * defaultHoursNotComplete;
      noOfHoursNotCompleteCost +=
        notCompletedRounds * defaultHoursNotCompleteCost;
    }
    const teamQualityError =
      ambulanceDiversions +
      patientsWaiting +
      arrivalsWaiting +
      requestWaiting +
      extraStaff +
      noOfHoursNotComplete;
    const teamCost =
      ambulanceDiversionsCost +
      patientsWaitingCost +
      arrivalsWaitingCost +
      extraStaffCost +
      noOfHoursNotCompleteCost;
    // ACTIVITY
    let activity = {
      ambulanceDiversionsActivity,
      patientsWaitingActivity,
      arrivalsWaitingActivity,
      requestWaitingActivity,
      extraStaffActivity,
    };

    return { activity, teamCost, teamQualityError };
  };
  const singleActivtyCount = (
    gameData,
    current_round,
    game_complete_status
  ) => {
    let noOfHoursNotComplete = 0;
    //emergencyRoomActivity
    let emergencyRoomAmbulanceDiversionsActivity = 0;
    let emergencyRoomPatientsWaitingActivity = 0;
    let emergencyRoomExtraStaffActivity = 0;

    //surgeryRoomActivity
    let surgeryRoomArrivalsWaitingActivity = 0;
    let surgeryRoomExtraStaffActivity = 0;
    let surgeryRoomRequestWaitingActivity = 0;

    //criticalCareRoomActivity
    let criticalCareRoomArrivalsWaitingActivity = 0;
    let criticalCareRoomExtraStaffActivity = 0;
    let criticalCareRoomRequestWaitingActivity = 0;

    //stepDownUnitActivity
    let stepDownUnitArrivalsWaitingActivity = 0;
    let stepDownUnitExtraStaffActivity = 0;
    let stepDownUnitRequestWaitingActivity = 0;

    for (const data of gameData) {
      if (data?.role_id === 1) {
        emergencyRoomAmbulanceDiversionsActivity += Number(
          data?.ambulance_diversions_count
        );
        emergencyRoomPatientsWaitingActivity += Number(
          data?.patients_waiting_count
        );
        emergencyRoomExtraStaffActivity += Number(data.total_extra_staff);
      } else if (data?.role_id === 2) {
        surgeryRoomArrivalsWaitingActivity += Number(
          data?.data?.walking?.patient_count
        );
        surgeryRoomExtraStaffActivity += Number(data.total_extra_staff);
        surgeryRoomRequestWaitingActivity += [
          "coming_from_emergency",
          "coming_from_critical",
          "coming_from_surgery",
        ].reduce(
          (sum, key) =>
            sum +
            (data?.[key]?.filter((waiting) => waiting.status === 1)
              ?.length || 0),
          0
        );
      } else if (data?.role_id === 3) {
        criticalCareRoomArrivalsWaitingActivity += Number(
          data?.data?.walking?.patient_count
        );
        criticalCareRoomExtraStaffActivity += Number(data.total_extra_staff);
        criticalCareRoomRequestWaitingActivity += [
          "coming_from_emergency",
          "coming_from_critical",
          "coming_from_surgery",
        ].reduce(
          (sum, key) =>
            sum +
            (data?.[key]?.filter((waiting) => waiting.status === 1)
              ?.length || 0),
          0
        );
      } else if (data?.role_id === 4) {
        stepDownUnitArrivalsWaitingActivity += Number(
          data?.data?.walking?.patient_count
        );
        stepDownUnitExtraStaffActivity += Number(data.total_extra_staff);
        stepDownUnitRequestWaitingActivity += [
          "coming_from_emergency",
          "coming_from_critical",
          "coming_from_surgery",
        ].reduce(
          (sum, key) =>
            sum +
            (data?.[key]?.filter((waiting) => waiting.status === 1)
              ?.length || 0),
          0
        );
      }
    }

    if (current_round > 1) {
      // if rounds is 24 then no need calculation
      defaultRounds === current_round &&
      game_complete_status === "times-complete"
        ? (noOfHoursNotComplete = 1)
        : (noOfHoursNotComplete = defaultRounds - current_round);
      // noOfHoursNotComplete += notCompletedRounds * defaultHoursNotComplete;
    }
    let emergencyRoom = {
      ambulanceDiversions: emergencyRoomAmbulanceDiversionsActivity,
      patientsWaiting: emergencyRoomPatientsWaitingActivity,
      extraStaff: emergencyRoomExtraStaffActivity,
    };

    let surgeryRoom = {
      arrivalsWaiting: surgeryRoomArrivalsWaitingActivity,
      extraStaff: surgeryRoomExtraStaffActivity,
      requestsWaiting: surgeryRoomRequestWaitingActivity,
    };

    let criticalCare = {
      arrivalsWaiting: criticalCareRoomArrivalsWaitingActivity,
      extraStaff: criticalCareRoomExtraStaffActivity,
      requestsWaiting: criticalCareRoomRequestWaitingActivity,
    };

    let stepDownUnit = {
      arrivalsWaiting: stepDownUnitArrivalsWaitingActivity,
      extraStaff: stepDownUnitExtraStaffActivity,
      requestsWaiting: stepDownUnitRequestWaitingActivity,
    };
    return {
      emergencyRoom,
      surgeryRoom,
      criticalCare,
      stepDownUnit,
      noOfHoursNotComplete,
    };
  };

  const teamParform =
    allTeam?.map((item, index) => {
      const data = countQualityErrors(
        item?.games,
        item.current_round,
        item?.game_complete_status
      );
      return data;
    }) || [];

  const getTeamactivity =
    allTeam?.map((item, index) => {
      const data = singleActivtyCount(
        item?.games,
        item.current_round,
        item?.game_complete_status
      );
      return data;
    }) || [];

  const blendedScores =
    allTeam?.map((item, index) => {
      const data = countQualityErrors(
        item?.games,
        item.current_round,
        item?.game_complete_status
      );
      const blendedScore =
        data.teamQualityError / blendedTeamQualityError +
        data.teamCost / blendedTeamCost / 2;

      return blendedScore.toFixed(2);
    }) || [];

  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (param?.id) {
          setAllTeams(gameInfo?.data);
          const modifyArray =
            allTeam?.map((item, index) => `Team ${index + 1}: ${item.name}`) ||
            [];
          setTransformedArray(modifyArray);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTeam?.length, gameInfo?.isLoading]);

  let yourDataArray = blendedScores;
  let maxValueInData = Math.max(...yourDataArray);
  // Handle button state changes
  const handleButtonState = (index, value) => {
    let temp = buttonArray;
    temp = temp.map((val, ind) => {
      if (ind === index) {
        val.selected = !val.selected;
      } else {
        val.selected = false;
      }
      return val;
    });
    setbuttonArray([...temp]);
  };

  useEffect(() => {
    if (allTeam?.length) {
      // Chart setup
      const data = {
        labels: transformedArray,
        datasets: [
          {
            data: blendedScores,
            backgroundColor: [
              "rgba(118, 166, 127, 1)",
              "rgba(213, 104, 103, 1)",
              "rgba(91, 192, 190, 1)",
              "rgba(233, 196, 106, 1)",
              "rgba(100, 134, 188, 1)",
              "rgba(244, 162, 97, 1)",
            ],
            // borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            borderSkipped: "bottom",
            borderRadius: 5,
          },
        ],
      };
      // Additional data for horizontal scrollbar
      const data2 = {
        labels: [],
        datasets: [
          {
            label: "Weekly Sales",
            data: [],
          },
        ],
      };

      // Chart configuration
      const config = {
        type: "bar",
        data,
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 10,
            },
          },
          onClick: (event, elements) => {
            if (elements && elements.length > 0) {
              performModalShow(elements);
            }
          },
          scales: {
            y: {
              max:
                maxValueInData !== 0
                  ? Math.ceil(maxValueInData / 5) * 5
                  : Math.ceil(15 / 5) * 5, // Round up  5
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                max:
                  maxValueInData !== 0
                    ? Math.ceil(maxValueInData / 5) * 5
                    : Math.ceil(15 / 5) * 5,
                suggestedMax:
                  maxValueInData !== 0
                    ? Math.ceil(maxValueInData / 5) * 5
                    : Math.ceil(15 / 5) * 5,
                callback: function (value, index, values) {
                  return index % 5 === 0 ? value : "";
                },
                display: false,
              },

              grid: {
                drawTicks: false,
                color: "#404040",
                drawOnChartArea: true,
                ticks: {
                  color: [
                    "rgba(118, 166, 127, 1)",
                    "rgba(213, 104, 103, 1)",
                    "rgba(91, 192, 190, 1)",
                    "rgba(233, 196, 106, 1)",
                    "rgba(100, 134, 188, 1)",
                    "rgba(244, 162, 97, 1)",
                  ],
                },
              },
            },
            x: {
              border: { color: "white" },
              ticks: {
                color: [
                  "rgba(118, 166, 127, 1)",
                  "rgba(213, 104, 103, 1)",
                  "rgba(91, 192, 190, 1)",
                  "rgba(233, 196, 106, 1)",
                  "rgba(100, 134, 188, 1)",
                  "rgba(244, 162, 97, 1)",
                ],
                font: {
                  weight: "700",
                  size: "14px",
                },
                callback: (value, index, values) => {
                  const labelArray = transformedArray;
                  const parts = labelArray[index].split(":");
                  if (parts.length === 2) {
                    return [parts[0].trim() + ":", parts[1].trim()];
                  } else {
                    return value;
                  }
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              anchor: "top",
              align: "top",
              color: "black",
              font: {
                weight: "700",
                size: "18px",
                family: "Futura PT",
              },
              formatter: (value, context) => {
                if (value > 0) {
                  return value;
                } else {
                  return null;
                }
              },
            },
          },
        },
        plugins: [
          {
            // Plugin to highlight a specific range after drawing
            afterDraw: function (chart) {
              const ctx = chart.ctx;
              const xAxis = chart.scales["x"];
              const yAxis = chart.scales["y"];
              const rangeStart = yAxis.getPixelForValue(
                buttonArray.find((item) => item.selected)?.value[0] || 0
              );
              const rangeEnd = yAxis.getPixelForValue(
                buttonArray.find((item) => item.selected)?.value[1] || 0
              );
              ctx.save();
              ctx.canvas.style.zIndex = 10;
              ctx.fillStyle = "rgba(253, 207, 118, .20)";
              ctx.fillRect(
                xAxis.left,
                rangeStart,
                xAxis.width,
                rangeEnd - rangeStart
              );
              ctx.restore();
            },
          },
          ChartDataLabels,
        ],
      };

      // Additional configuration for horizontal scrollbar
      const config2 = {
        type: "bar",
        data2,
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              // bottom: ,
            },
          },
          scales: {
            x: {
              color: "white",
              border: { color: "white" },
              grid: {
                color: "white",
              },
            },
            y: {
              max:
                maxValueInData !== 0
                  ? Math.ceil(maxValueInData / 5) * 5
                  : Math.ceil(15 / 5) * 5,
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                max:
                  maxValueInData !== 0
                    ? Math.ceil(maxValueInData / 5) * 5
                    : Math.ceil(15 / 5) * 5, // Round up  5
                suggestedMax:
                  maxValueInData !== 0
                    ? Math.ceil(maxValueInData / 5) * 5
                    : Math.ceil(15 / 5) * 5,
                callback: function (value, index, values) {
                  return index % 5 === 0 ? value : "";
                },
                minor: {
                  stepSize: 0.5,
                },
                font: {
                  weight: 500,
                },
                color: "white",
              },
              afterFit: (ctx) => {
                ctx.width = 34.4;
              },
              border: { color: "white" },
              grid: {
                color: (context) => {
                  const tickValue = context.tick.value;
                  return tickValue % 5 === 0 ? "white" : "#404040";
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              display: false,
            },
            datalabels: {
              anchor: "top",
              align: "top",
              color: "white",
              font: {
                weight: "700",
                size: "18px",
              },
              formatter: (value, context) => {
                if (value > 0) {
                  return value;
                } else {
                  return null;
                }
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      };

      // render init block
      const myChart = new Chart(document.getElementById("myChart"), config);
      const myChart2 = new Chart(document.getElementById("myChart2"), config2);
      const box = document.getElementById("box");

      // Adjust the width based on the number of bars
      const barlength = myChart.data.labels.length;
      if (barlength > 7) {
        const cardWidth = 700 + (barlength - 7) * 100;
        box.style.width = `${cardWidth}px`;
      }

      return () => {
        myChart.destroy();
        myChart2.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonArray, transformedArray]);

  // tooltip content
  const renderTooltip = (props) => (
    <Tooltip className="tooltip-light" id="button-tooltip" {...props}>
      {constant?.teamParform}
    </Tooltip>
  );

  const performModalShow = (elements) => {
    const targetIndex = elements && elements[0]?.index;
    const teamPerform = teamParform[targetIndex];
    const teamIndex = transformedArray[targetIndex];
    const teamActivity = getTeamactivity[targetIndex];
    setSingleActivityCount(teamActivity);
    setTeamName(teamIndex);
    setGameData(teamPerform);
    setPerformModal(!performModal);
  };

  return (
    <>
      {allTeam?.length ? (
        <div className="bg-black">
          {isLoggedIn && (
            <BsX
              onClick={() => {
                navigate(`/group/${encrypt(String(session?.id))}`);
              }}
              className="end-2 top-2 text-2xl position-absolute text-white"
            />
          )}
          <div className="min-h-screen text-center text-white py-10 chart-scroll">
            <Container>
              <p className="pb-4 text-uppercase ls-widest text-17">
                {session && session?.name} (
                {session && moment(sessionDate).format("MMMM D, YYYY")})
              </p>
              <h1 className="text-futura text-white font-semibold mb-6">
                {constant?.teamScore}
              </h1>
              <p className="font-bold mb-4 text-15">
                {constant?.showScore}
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span>
                    <BsQuestionCircle className="ms-1" />
                  </span>
                </OverlayTrigger>
              </p>
              <Form className="hstack flex-wrap gap-4 justify-content-center mb-6">
                {buttonArray?.map((item, index) => {
                  return (
                    <Form.Check
                      key={index}
                      className="yellow-check"
                      type={"checkbox"}
                      id="1"
                    >
                      <Form.Check.Input
                        checked={item?.selected}
                        onChange={() => handleButtonState(index, item.value)}
                        type="checkbox"
                      />
                      <Form.Check.Label className="text-white font-regular text-sm">
                        {item.text}
                      </Form.Check.Label>
                    </Form.Check>
                  );
                })}
              </Form>

              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="pb-8" style={{ width: "35px" }}>
                    <canvas
                      style={{
                        marginBottom: `${isChrome === false ? "37px" : "45px"}`,
                      }}
                      id="myChart2"
                    ></canvas>
                  </div>
                  <div
                    className="pb-8"
                    style={{ maxWidth: "700px", overflowX: "scroll" }}
                  >
                    <div
                      id="box"
                      style={{ width: "calc(700px - 35px)", height: "500px" }}
                    >
                      <canvas id="myChart"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-white text-start text-xs mt-10">
                {constant?.gameMessage}{" "}
                <a
                  className="text-secondary text-no-decoration font-regular ls-wider text-break"
                  target="_blank"
                  rel="noreferrer"
                  href="https://fridaynightattheer.com/"
                >
                  {constant?.fridayNightEmail}
                </a>
              </p>
            </Container>
          </div>
        </div>
      ) : (
        <TeamPerformanceLoder />
      )}
      {performModal && (
        <TeamPerformanceModal
          gameData={gameData}
          show={performModal}
          teamName={teamName}
          singleActivityCount={singleActivityCount}
          hide={() => performModalShow()}
        />
      )}
    </>
  );
};

export default TeamPerformance;
