import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGetSessions, useGetUsers, useRemoveSession } from "@/customHooks";
import { modal } from "@/utils/constant";

function CancelModal({ hide, show, session, organization_sheet }) {
  const cancelSessionMutation = useRemoveSession();
  const navigate = useNavigate();

  const getUser = useGetUsers();
  const { refetch } = useGetSessions({
    id: getUser?.data?.organization?.id,
    isAscending: true,
    currentPage: 1,
  });

  const handleCancelSession = async (e) => {
    const session_remove_data = {
      id: Number(session?.id),
      total_sheet: session.organization_sheet + organization_sheet,
      organization_id: session.organization_id,
    };
    await cancelSessionMutation.mutateAsync(session_remove_data);
    refetch();
    navigate("/session");
  };

  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.cancleSessionModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p>
            {modal?.cancleSessionModalText}{" "}
            <span className="font-bold">
              {modal?.cancleSessionModalBodyText}
            </span>{" "}
            {modal?.cancleSessionModalQuestion}
          </p>
          <Row className="mt-8">
            <Col>
              <Button
                variant="secondary"
                className="font-semibold w-full"
                onClick={() => handleCancelSession()}
              >
                {modal?.cancleSessionModal}
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
                onClick={hide}
              >
                {modal?.cancleSessionNevermind}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CancelModal;
