import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";

function SingleChart({ data, name }) {
  let stepSizeQualityLoss = 3000;
  let stepSizeExcessCost = 30000;
  let extraStepSizeQualityLoss = 1000;
  let extraStepSizeExcessCost = 10000;
  let qualityLossInfo = name === "Quality Loss";
  const [gameData, setGameData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        if (data) {
          setGameData([data]);
        }
      };
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData.length]);

  const generateTicks = (value, step) => {
    const ticks = [];
    for (let i = step; i <= value; i += step) {
      ticks.push(i);
    }
    return ticks;
  };
  const formatYAxisValue = (value) => {
    return `${value / 1000}k`;
  };
  const dataApi = gameData;
  const labels = gameData;
  const teamData = {
    labels: labels,
    datasets: [
      {
        label: labels,
        data: dataApi,
        backgroundColor: ["#FDCF76"],
        borderColor: "black",
        borderWidth: 0,
        minBarLength: "103px",
        barThickness: qualityLossInfo ? 97 : 90,
      },
    ],
  };
  const options = {
    // responsive: false,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: 0,
        max: qualityLossInfo
          ? gameData > 13000
            ? Math.ceil(gameData / stepSizeQualityLoss) * stepSizeQualityLoss +
              extraStepSizeQualityLoss
            : 13000
          : gameData > 130000
          ? Math.ceil(gameData / stepSizeExcessCost) * stepSizeExcessCost +
            extraStepSizeExcessCost
          : 130000,
        beginAtZero: false,
        ticks: {
          max: qualityLossInfo
            ? Math.ceil(gameData / stepSizeQualityLoss) * stepSizeQualityLoss +
              extraStepSizeQualityLoss
            : gameData > 130000
            ? Math.ceil(gameData / stepSizeExcessCost) * stepSizeExcessCost +
              extraStepSizeExcessCost
            : 130000,
          stepSize: qualityLossInfo
            ? extraStepSizeQualityLoss
            : extraStepSizeExcessCost,
          callback: function (value, index, values) {
            const qualityLossTicks =
              gameData > 13000
                ? generateTicks(
                    Math.ceil(gameData / stepSizeQualityLoss) *
                      stepSizeQualityLoss,
                    stepSizeQualityLoss
                  )
                : [3000, 6000, 9000, 12000];
            const otherTicks =
              gameData > 130000
                ? generateTicks(
                    Math.ceil(gameData / stepSizeExcessCost) *
                      stepSizeExcessCost,
                    stepSizeExcessCost
                  )
                : [30000, 60000, 90000, 120000];

            const ticksToDisplay = qualityLossInfo
              ? qualityLossTicks
              : otherTicks;

            return ticksToDisplay.includes(value)
              ? formatYAxisValue(value)
              : "";
          },
        },
        border: { color: "black" },
        color: "black",
        grid: {
          tickColor: "rgba(0, 0, 0, 1)",
          color: "rgba(0, 0, 0, 0)",
        },
      },
      x: {
        beginAtZero: true,
        stepSize: 1,
        ticks: {
          display: false,
        },
        grid: {
          color: "black",
          tickColor: "rgba(0, 0, 0, 0)",
        },
        color: "black",
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "top", // Adjust the anchor position as needed
        align: "top",
        color: "black", // Set the color of the labels
        font: {
          weight: "600",
          size: "18px", // Adjust the font size as needed
          family: "Open Sans",
        },
        formatter: (value, context) => {
          return qualityLossInfo
            ? value.toLocaleString("en-en")
            : `$${value.toLocaleString("en-en")}`;
        },
      },
    },
  };

  return (
    <>
      <div className="mx-auto" style={{ maxWidth: "132px", overflowX: "none" }}>
        <div
          className="hstack"
          id="box"
          style={{ width: "132px", height: "500px" }}
        >
          {gameData?.length && (
            <Bar
              data={teamData}
              options={options}
              plugins={[ChartDataLabels]}
            />
          )}
          <span
            className="border-end border-black my-auto"
            style={{ height: "96%" }}
          ></span>
        </div>
      </div>
      <hr className="d-sm-none d-block border-black border-2 border-top mt-n3 me-n4" />
    </>
  );
}

export default SingleChart;
