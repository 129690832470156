import React from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TeamPerformanceLoder = () => {
  return (
    <>
      <div className="bg-white">
        <div className="min-h-screen text-center text-white py-10 chart-scroll">
          <Container>
            <p className="pb-4 text-uppercase ls-widest text-17">
              <Skeleton />
            </p>
            <h1 className="text-futura text-white font-semibold mb-6">
              <Skeleton />
            </h1>
            <p className="font-bold mb-4 text-15">
              <Skeleton />
            </p>

            <p>
              <Skeleton style={{ height: "600px", width: "100%" }} />
            </p>
          </Container>
        </div>
      </div>
    </>
  );
};

export default TeamPerformanceLoder;
