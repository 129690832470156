import { React, useState } from "react";
import {
  Modal,
  Button,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useFormik } from "formik";
import { BsQuestionCircle } from "react-icons/bs";
import { addParticipantValidate } from "@/utils/validation";
import {
  useFetchTeamParticipant,
  useGetParticipant,
  usePostParticipant,
  useUpdateAddParticipate,
} from "@/customHooks/index";
import { useParams } from "react-router-dom";
import { decrypt } from "@/utils/crypto";
import { usePatchParticipantData } from "@/customHooks/useParticipants";
import { constant, modal } from "@/utils/constant";

function AddParticipants({
  getTeam,
  hide,
  show,
  isAddParticipantAssign,
  participantData,
  teamId,
}) {
  const params = useParams();
  const [isDisabled, setIsDisabled] = useState(false);
  const { setIsParticipantDataUpdate } = usePatchParticipantData();
  const { refetch } = useGetParticipant(Number(decrypt(params?.id)));
  const getTeamParticipant = useFetchTeamParticipant(
    Number(decrypt(params?.id))
  );
  const updateMutation = useUpdateAddParticipate();
  const addParticipantMutation = usePostParticipant();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      // email: "",
      private_note: "",
    },
    validationSchema: addParticipantValidate,
    onSubmit: async (values) => {
      setIsDisabled(true);
      const addParticipatData = {
        session_id: Number(decrypt(params?.id)),
        first_name: values.firstName,
        last_name: values.lastName,
        // email: values.email,
        private_note: values.private_note,
      };
      try {
        const data = await addParticipantMutation.mutateAsync(
          addParticipatData
        );
        setIsParticipantDataUpdate(true);
        await refetch();
        if (isAddParticipantAssign) {
          if (data?.status === 201) {
            let newValue = {
              participant_id: data?.data[0]?.id,
              role_id: isAddParticipantAssign?.role_id,
              team_id: teamId,
              session_id: isAddParticipantAssign?.session_id,
              current_status: 0,
              file_maker_participant_id: data?.file_maker_participant_id
            };
            await updateMutation.mutateAsync(newValue);
            participantData.refetch();
            getTeam.refetch();
            setIsParticipantDataUpdate(true);
            getTeamParticipant.refetch();
          } else {
          }
        }
        setIsDisabled(false);
        hide();
      } catch (error) {}
    },
  });

  return (
    <>
      <Modal show={show} onHide={hide} size="md" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.addParticipantModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10">
          <form className="mx-auto form" onSubmit={formik.handleSubmit}>
            <div>
              <label className="form-label" htmlFor="firstName">
                {constant?.firstName}
              </label>
              <FormControl
                value={formik.values.firstName}
                onChange={formik.handleChange}
                name="firstName"
                placeholder=""
              />
              {formik.submitCount > 0 && formik.errors.firstName && (
                <div className="text-danger">{formik.errors.firstName}</div>
              )}
            </div>
            <div className="mt-6">
              <label className="form-label" htmlFor="lastName">
                {constant?.lastName}
              </label>
              <FormControl
                value={formik.values.lastName}
                onChange={formik.handleChange}
                name="lastName"
                placeholder=""
              />
              {formik.submitCount > 0 && formik.errors.lastName && (
                <div className="text-danger">{formik.errors.lastName}</div>
              )}
            </div>
            {/* <div className="mt-6">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2" className="tooltip-light">
                    <p className="mb-2">{modal?.addParticipantTooltipText}</p>
                    <p>{modal?.addParticipantText}</p>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className="d-inline-flex align-items-center form-label"
                  >
                    <span className="me-2">{modal?.emailAddress}</span>
                    <span ref={ref} className="lh-none">
                      <BsQuestionCircle />
                    </span>
                  </div>
                )}
              </OverlayTrigger>
              <FormControl
                name="email"
                placeholder=""
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.submitCount > 0 && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div> */}
            <div className="mt-6">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2" className="tooltip-light">
                    <p className="mb-2">{modal?.privateNoteModelTooltip}</p>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className="d-inline-flex align-items-center form-label"
                  >
                    <span className="me-2">{modal?.privateNote}</span>
                    <span ref={ref} className="lh-none">
                      <BsQuestionCircle />
                    </span>
                  </div>
                )}
              </OverlayTrigger>
              <FormControl
                name="private_note"
                type="text"
                value={formik.values.private_note}
                onChange={formik.handleChange}
              />
              {formik.submitCount > 0 && formik.errors.private_note && (
                <div className="text-danger">{formik.errors.private_note}</div>
              )}
            </div>
            <div className="px-2">
              <Button
                variant="secondary"
                size="lg"
                className="w-full mt-lg-16 mt-10"
                type="submit"
                disabled={isDisabled}
              >
                {modal?.addParticipantButtonText}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddParticipants;
