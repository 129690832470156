import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Participants, Session, Login, ForgotPassword } from "../pages";
import PrivateRoute from "./privateRouter";
import { GuestParticipants } from "../pages/index";
import { useGetSessions, useGetUsers } from "../customHooks";
import UpdatePassword from "../pages/updatePassword";
import TeamPerformance from "../pages/teamPerformance";
import * as sessionApi from "@/api/index";

const MainRouter = () => {
  const isLoggedIn = localStorage.getItem("token") ? true : false;
  const navigate = useNavigate();
  const router = useLocation();
  const location = useLocation();
  const getUser = useGetUsers();
  const [isAscending, setAscending] = useState(true);
  const [show, setShow] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [forgotErrorDescription, setForgotErrorDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = urlParams.get("access_token");
      const urlError = urlParams.get("error_description");
      if (urlError) {
        setForgotErrorDescription(urlError);
      } else {
        const expiryTimestamp = urlParams.get("expires_at");
        const isTokenExpired = () => {
          const currentTimestamp = Math.floor(Date.now() / 1000);
          return currentTimestamp >= parseInt(expiryTimestamp, 10);
        };
        if (accessToken && expiryTimestamp) {
          if (isTokenExpired()) {
            navigate("/login");
          } else {
            setResetToken(accessToken);
          }
        }
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, data, refetch } = useGetSessions({
    id: getUser?.data?.organization?.id,
    isAscending,
    currentPage,
  });
  
  const handlePageChange = async (newPage) => {
    const totalPage = data && data?.totalPages
    if (newPage <= totalPage && newPage >= 1) {
      sessionApi.useFetchSession({
        id: getUser?.data?.organization?.id,
        isAscending,
        currentPage: newPage,
      });
      setCurrentPage(newPage);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    handlePageChange(1);
    setCurrentPage(1);
  }, [isAscending]);

  useEffect(() => {
    if (!isLoggedIn && router.pathname === "/participants/:id") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleAscending = async(isBool) => {
    const data = await sessionApi.updateSession();
    setAscending(isBool);
    if (data.length >= 0) {
      refetch(); 
    }
    if (isLoggedIn && location.pathname.split("/")[1] !== "session") {
      navigate("/session");
    }
  };

  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAscending]);

  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/teams-performance/:id"
        element={<TeamPerformance isLoggedIn={isLoggedIn} />}
      />
      <Route
        path="/update-password"
        element={
          <UpdatePassword
            forgotErrorDescription={forgotErrorDescription}
            accessToken={resetToken}
          />
        }
      />
      <Route
        path="/"
        element={isLoggedIn ? <Navigate to="/session" /> : <Login />}
      />
      <Route
        path="/session"
        element={
          <PrivateRoute>
            <Session
              handlePageChange={(e) => handlePageChange(e)}
              currentPage={currentPage}
              totalPages={data && data?.totalPages}
              data={data}
              refetch={refetch}
              show={show}
              isLoading={isLoading}
              isAscending={isAscending}
              handleAscending={handleAscending}
              handleShow={handleShow}
              user_data={getUser?.data}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/:dynamicPart/:id"
        element={
          isLoggedIn ? (
            <PrivateRoute>
              <Participants
                refetch={refetch}
                show={show}
                isAscending={isAscending}
                handleAscending={handleAscending}
                handleShow={handleShow}
                isLoggedIn={isLoggedIn}
              />
            </PrivateRoute>
          ) : (
            <GuestParticipants isLoggedIn={isLoggedIn} />
          )
        }
      />
      <Route path="*" element={<Navigate to={isLoggedIn ? "session" : ""} />} />
    </Routes>
  );
};

export default MainRouter;
