import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { modal } from "@/utils/constant";

function PauseModal({ hide, show, handlePause }) {
  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.paushModalTitalText}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p className="mb-3">{modal?.puashModalBodyText}</p>
          {/* <p>{modal?.puashModalBodyPara}</p> */}
          <Row className="mt-8">
            <Col>
              <Button variant="secondary" className="font-semibold w-full" onClick={()=> handlePause()}>
                {modal?.puashModalButtonText}
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
                onClick={hide}
              >
                {modal?.cancle}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default PauseModal;
