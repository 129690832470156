import { Header, SessionHeader } from "@/components";
function CommonLayout({
  children,
  session_id,
  guestParticipants,
  activeKey,
  handleShow,
  handleAscending,
  isAscending,
}) {
  return (
    <>
      <Header
        handleShow={handleShow}
        handleAscending={handleAscending}
        session_id={session_id}
        guestParticipants={guestParticipants}
      />
      <SessionHeader
        guestParticipants={guestParticipants}
        isAscending={isAscending}
        activeKey={activeKey}
      />
      {children}
    </>
  );
}
export default CommonLayout;
