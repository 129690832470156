import { React, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  FormControl,
  Form,
} from "react-bootstrap";
import { useFormik } from "formik";
import { Logo } from "@/assets/images/index";
import { Link, useNavigate } from "react-router-dom";
import { updateValidate } from "@/utils/validation";
import { supabase } from "@/config/supabase";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { constant } from "@/utils/constant";

const UpdatePassword = ({ accessToken, forgotErrorDescription }) => {
  const navigate = useNavigate();
  const [errorPasswordMatch, setErrorPasswordMatch] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      new_password: "",
    },
    validationSchema: updateValidate,
    onSubmit: async (values) => {
      if (!accessToken) {
        return;
      }
      try {
        if (values.new_password.trim() === values.password.trim()) {
          const { error } = await supabase.auth.updateUser({
            password: values.password,
          });
          if (error) {
            setErrorPasswordMatch(error?.message);
          } else {
            navigate("/login");
          }
        } else {
          setErrorPasswordMatch("Password did not match");
        }
      } catch (error) {
        console.error("Error resetting password:", error.message);
      }
    },
  });

  const handleSignInClick = (e) => {
    e.preventDefault();
    setErrorPasswordMatch("");
    formik.handleSubmit();
  };

  return (
    <section className="bg-primary min-h-screen hstack ">
      <Container>
        <Row>
          <Col lg={4} md={6} className="mx-auto">
            <div className="text-center">
              <Image src={Logo} className="h-10" />
              <p className="text-light mt-4">
                {constant?.virtual}
                <span className="font-bold ms-1">{constant?.fridayNight}</span>
              </p>
            </div>
            {!accessToken ? (
              <h3 className="text-light text-center">
                {forgotErrorDescription
                  ? forgotErrorDescription
                  : "No reset token found in the URL"}
              </h3>
            ) : (
              <form className="mt-10 form mx-auto">
                <div className="mt-6">
                  <label className="form-label text-light" htmlFor="password">
                    {constant?.newPassword}
                  </label>
                  <Form.Group className="position-relative vstack justify-content-center">
                    <FormControl
                      type={showPassword ? "text" : "password"}
                      className="remove_key_icon_safari"
                      name="password"
                      value={formik.values.password}
                      onChange={(e) => {
                        setErrorPasswordMatch("");
                        formik.handleChange(e);
                      }}
                    />
                    <span
                      className="position-absolute end-5 text-primary cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </span>
                  </Form.Group>

                  {formik.submitCount > 0 && formik.errors.password && (
                    <div className="text-danger">{formik.errors.password}</div>
                  )}
                </div>
                <div className="mt-6">
                  <label
                    className="form-label text-light"
                    htmlFor="new_password"
                  >
                    {constant?.confirmNewPassword}
                  </label>
                  <Form.Group className="position-relative vstack justify-content-center">
                    <FormControl
                      type={showConfirmPassword ? "text" : "password"}
                      name="new_password"
                      className="remove_key_icon_safari"
                      value={formik.values.new_password}
                      onChange={(e) => {
                        setErrorPasswordMatch("");
                        formik.handleChange(e);
                      }}
                    />

                    <span
                      className="position-absolute end-5 text-primary cursor-pointer"
                      onClick={() =>
                        setConfirmShowPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                    </span>
                  </Form.Group>
                  {errorPasswordMatch && (
                    <div className="text-danger">{errorPasswordMatch}</div>
                  )}
                </div>
                <div className="px-2">
                  <Button
                    className="w-full mt-10 mb-3 btn btn-secondary btn-lg"
                    type="submit"
                    onClick={(e) => handleSignInClick(e)}
                  >
                    {constant?.updatePassword}
                  </Button>
                  <Link
                    to={`/login`}
                    className="text-light hstack justify-content-center text-no-decoration"
                  >
                    {constant?.login}
                  </Link>
                </div>
              </form>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default UpdatePassword;
