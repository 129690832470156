import { supabase } from "../config/supabase";
import { defaultRounds, ltDefaultRounds } from "@/utils/activity";

export const fetchGameData =  async(id) => {
  try {
    if (!id) return [];

    const { data: teamData } = await supabase
      .from("team")
      .select("*")
      .eq("session_id", id)
      .order("team_index", { ascending: true });

    const teamWithGamesPromises = teamData.map(async (team, index) => {
      const { data: games } = await supabase
        .from("games")
        .select("*")
        .match({
          session_id: team?.session_id,
          team_id: team?.id,
        })
        .lt(
          "round_no",
          team.current_round !== defaultRounds
            ? team.current_round
            : ltDefaultRounds
        );

      return { ...team, games };
    });

    const teamWithGames = await Promise.all(teamWithGamesPromises);
    return teamWithGames;
  } catch (error) {
    console.error(error);
    return [];
  }
};
