import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import { modal } from "@/utils/constant";

function EditTimeModal({ hide, show }) {
  const addMinutes = [5, 1, 1, 5];

  function increment(value) {
    const [hours, minutes] = formik.values.time.split(":");
    const newMinutes = (parseInt(minutes) + value) % 60;
    const newHours =
      (parseInt(hours) + Math.floor((parseInt(minutes) + value) / 60)) % 24;
    formik.setFieldValue(
      "time",
      `${newHours.toString().padStart(2, "0")}:${newMinutes
        .toString()
        .padStart(2, "0")}`
    );
      }

  function decrement(value) {
    if (formik.values.time === "00:00") {
      formik.setFieldValue("time", "00:00");
    } else {
      const [hours, minutes] = formik.values.time.split(":");
      const newMinutes = (parseInt(minutes) - value + 60) % 60;
      const newHours =
        (parseInt(hours) + Math.floor((parseInt(minutes) - value) / 60)) % 24;
      formik.setFieldValue(
        "time",
        `${newHours.toString().padStart(2, "0")}:${newMinutes
          .toString()
          .padStart(2, "0")}`
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      time: "00:00",
    },
    onSubmit: (values) => {},
  });

  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.editTimeModalTital}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p className="mb-3">{modal?.editTimeModalBodyText}</p>
          <p>{modal?.editTimeModalAdjustment}</p>
          <form onClick={formik.handleSubmit} className="mt-8">
            {/* <Button
              variant="secondary"
              className="btn-square rounded-2"
              size="sm"
              onClick={decrement}
            >
              <BsDashLg />
            </Button> */}
            <InputMask
              className="form-control w-20 text-center border-0 bg-transparent text-futura text-lg mx-auto mb-5"
              mask="99:99"
              placeholder="00:00"
              name="time"
              value={formik.values.time}
              onChange={formik.handleChange}
            />
                        {/* <Button
              variant="secondary"
              className="btn-square rounded-2"
              size="sm"
              onClick={increment}
            >
              <BsPlus />
            </Button> */}
            <div className="hstack gap-3 justify-content-center">
              {addMinutes?.map((item, index) => (
                <Button
                  key={index}
                  onClick={() =>
                    index === 0 || index === 1
                      ? decrement(item)
                      : increment(item)
                  }
                  variant="titanium-white"
                  size="sm"
                  className="font-base font-bold"
                >
                  {index === 0 || index === 1 ? "-" : "+"} {item} mins
                </Button>
              ))}
            </div>
          </form>
          <Row className="mt-8">
            <Col>
              <Button variant="secondary" className="font-semibold w-full">
                {modal?.applyButtonText}
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
              >
                {modal?.cancle}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default EditTimeModal;
