export const defaultHoursNotComplete = 500;
export const defaultHoursNotCompleteCost = 1000;
export const defaultAmbulanceDiversionsError = 200;
export const defaultAmbulanceDiversionsCost = 5000;
export const defaultPatientsWaitingError = 20;
export const defaultPatientsWaitingCost = 150;
export const defaultExtraStaffCost = 40;
export const defaultExtraStaffError = 5;
export const defaultArrivalsWaitingError = 20;
export const defaultArrivalsWaitingCost = 3750;
export const defaultRequestWaitingError = 20;
export const defaultRounds = 24;
export const blendedTeamQualityError = 1000;
export const blendedTeamCost = 10000;
export const ltDefaultRounds = 25;
