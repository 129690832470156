import { useQuery, useMutation, useQueryClient } from "react-query";
import * as participantsApi from "@/api/index";

export const usePatchParticipantData = () => {
  const queryClient = useQueryClient();
  const { data: initialState = false } = useQuery("key", {
    queryFn: () => Promise.resolve(true),
    enabled: false,
  });
  const setIsParticipantDataUpdate = async (newState) => {
    await queryClient.setQueryData("key", newState);
  };

  return {
    isParticipantDataUpdate: initialState,
    setIsParticipantDataUpdate,
  };
};

export function usePostParticipant() {
  return useMutation(participantsApi.useAddParticipant);
}
export function useGetParticipant(id) {
  return useQuery(["participants", [id]], () =>
    participantsApi.useFetchParticipants(id)
  );
}
export function useRemoveParticipant() {
  return useMutation(participantsApi.useDeleteParticipants);
}
export function useRemoveSingleParticipant() {
  return useMutation(participantsApi.useDeleteSingleParticipant);
}
export function useUpdateParticipant() {
  return useMutation(participantsApi.usePatchParticipant);
}
