import { React } from "react";
import {
	Modal,
	Button,
	FormControl,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import { useFormik } from "formik";
import { BsQuestionCircle } from "react-icons/bs";
import { addParticipantValidate } from "@/utils/validation";
import { useGetTeams, useUpdateParticipant } from "@/customHooks/index";
import { decrypt } from "@/utils/crypto";
import { useParams } from "react-router-dom";
import { usePatchParticipantData } from "@/customHooks/useParticipants";
import { constant, modal } from "@/utils/constant";

function EditParticipants({ hide, show, editData, refetch }) {
	const params = useParams();
	const updateMutation = useUpdateParticipant();
	const getTeam = useGetTeams(Number(decrypt(params?.id)));
	const { setIsParticipantDataUpdate } = usePatchParticipantData();

	const formik = useFormik({
		initialValues: {
			firstName: editData.first_name,
			lastName: editData.last_name,
			// email: editData.email,
			private_note: editData.private_note,
		},
		validationSchema: addParticipantValidate,
		onSubmit: async (values) => {
			if (
				values.firstName !== editData.first_name ||
				values.lastName !== editData.last_name ||
				// values.email !== editData.email
				values.private_note !== editData.private_note
			) {
				let newValue = {
					id: editData.id,
					first_name: values.firstName,
					last_name: values.lastName,
					// email: values.email,
					private_note: values.private_note,
				};
				try {
					await updateMutation.mutateAsync(newValue);
					await refetch();
					setIsParticipantDataUpdate(true);
					getTeam.refetch();
					hide();
				} catch (error) {}
			} else {
				hide();
			}
		},
	});

	return (
		<>
			<Modal show={show} onHide={hide} size="md" centered>
				<Modal.Header className="bg-secondary" closeButton>
					<Modal.Title>{modal?.editParticipantModalTital}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="py-10">
					<form className="mx-auto form">
						<div>
							<label className="form-label" htmlFor="firstName">
								{constant?.firstName}
							</label>
							<FormControl
								value={formik.values.firstName}
								onChange={formik.handleChange}
								name="firstName"
								placeholder="Jane"
							/>
							{formik.submitCount > 0 && formik.errors.firstName && (
								<div className="text-danger">{formik.errors.firstName}</div>
							)}
						</div>
						<div className="mt-6">
							<label className="form-label" htmlFor="lastName">
								{constant?.lastName}
							</label>
							<FormControl
								value={formik.values.lastName}
								onChange={formik.handleChange}
								name="lastName"
								placeholder="Doe"
							/>
							{formik.submitCount > 0 && formik.errors.lastName && (
								<div className="text-danger">{formik.errors.lastName}</div>
							)}
						</div>
						{/* <div className="mt-6">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2" className="tooltip-light">
                    <p className="mb-2">
                      {modal?.editModalFirstTooltip}
                    </p>
                    <p>
                    {modal?.editModalSecondTooltip}
                    </p>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    {...triggerHandler}
                    className="d-inline-flex align-items-center form-label"
                  >
                    <span className="me-2">{modal?.emailAddress}</span>
                    <span ref={ref} className="lh-none">
                      <BsQuestionCircle />
                    </span>
                  </div>
                )}
              </OverlayTrigger>
              <FormControl
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.submitCount > 0 && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div> */}
						<div className="mt-6">
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="button-tooltip-2" className="tooltip-light">
										<p className="mb-2">{modal?.privateNoteModelTooltip}</p>
									</Tooltip>
								}
							>
								{({ ref, ...triggerHandler }) => (
									<div
										{...triggerHandler}
										className="d-inline-flex align-items-center form-label"
									>
										<span className="me-2">{modal?.privateNote}</span>
										<span ref={ref} className="lh-none">
											<BsQuestionCircle />
										</span>
									</div>
								)}
							</OverlayTrigger>
							<FormControl
								name="private_note"
								type="text"
								value={formik.values.private_note}
								onChange={formik.handleChange}
							/>
							{formik.submitCount > 0 && formik.errors.private_note && (
								<div className="text-danger">{formik.errors.private_note}</div>
							)}
						</div>
						<div className="px-2">
							<Button
								variant="secondary"
								size="lg"
								className="w-full mt-lg-16 mt-10"
								onClick={formik.handleSubmit}
							>
								{modal?.saveChanges}
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
}
export default EditParticipants;
