import { React, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  FormControl,
  FormSelect,
} from "react-bootstrap";
import { BsDashLg, BsPlus } from "react-icons/bs";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetParticipant,
  useGetSingleSessions,
  useGetTeams,
  useGetUsers,
  useOrganizationUser,
  usePostSession,
  useUpdateSession,
  useRemoveSingleParticipant,
} from "@/customHooks/index";
import moment from "moment";
import { sessionModalValidate } from "@/utils/validation";
import CancelModal from "./cancelModal";
import { decrypt } from "@/utils/crypto";
import { constant, modal } from "@/utils/constant";

function SessionModal({ hide, id = 0, show, refetch }) {
  const navigate = useNavigate();

  const addSessionMutation = usePostSession();
  const updateSessionMutation = useUpdateSession();
  const { id: session_id } = useParams();
  const getSession = useGetSingleSessions(Number(decrypt(session_id)));
  const sessionData = getSession?.data || {};
  const session = sessionData?.session || {};
  const participantData = useGetParticipant(Number(session?.id));
  const removeSingleParticipant = useRemoveSingleParticipant();
  const getTeam = useGetTeams(Number(session?.id));
  const [isEdit, setIsEdit] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  // const [isCoFacilitatorDisabled, setCoFacilitatorDisabled] = useState(true);
  const [showCancel, setShowCancel] = useState(false);
  const [error, setError] = useState("");
  const { data } = useGetUsers();
  const [availableSeat, setAvailableSeat] = useState(
    data?.organization?.available_seat.toString()
  );
  const organizationUser = useOrganizationUser(data?.organization?.id);

  const check = [
    {
      payment: "Send me an invoice",
      desc: "You will receive an invoice from our team with options to pay by card, bank transfer or check.",
    }, //0
    {
      payment: "Purchase order",
      desc: "Submit a signed purchase order for the amount due to office@blearning.com.",
    }, //1
    {
      payment: "Participants will pay for themselves",
      desc: "We will provide you with a secure payment link to share with your participants.",
    }, //2
  ];

  const increment = () => {
    const count = parseInt(formik.values.count);
    setIsEdit(false);
    if (count >= 3) {
      setError("");
    }
    if (count >= 0) {
      if (count > 99) {
        setError("The maximum number of participants is 100.");
      } else {
        formik.setFieldValue("count", (count + 1).toString());
      }
    }
  };

  const decrement = () => {
    const count = parseInt(formik.values.count);
    setError("");
    // if (id === 1 && count < participantData?.data?.length) {
    //   setIsEdit(false);
    //   setError(constant?.editSessionError);
    // } else 
    if (count > 4) {
      setIsEdit(false);
      formik.setFieldValue("count", (count - 1).toString());
    } else {
      if (count > 0) {
        formik.setFieldValue("count", (count - 1).toString());
      }
      setError(constant?.editSessionError);
    }
  };

  const handleChange = (e) => {
    setIsEdit(false);
    const isChecked = e.target.checked;
    const value = isChecked ? e.target.value : "4";
    setIsChecked(isChecked);
    setAvailableSeat(value);
    formik.setFieldValue("amount", 0);
  };

  const formik = useFormik({
    initialValues: {
      name: id === 1 ? session?.name : "",
      date: id === 1 ? moment(session?.session_date).toDate() : "",
      amount:
        id === 1
          ? session?.payment_method === 2
            ? session?.amount + " per participant"
            : session?.amount
          : "",
      facilitator: id === 1 ? session?.facilitator?.id : "",
      co_facilitator: id === 1 ? session?.co_facilitator?.id : "",
      count: id === 1 ? session?.no_of_participant : "4",
      payment:
        id === 1
          ? check[session?.payment_method].payment
          : "Send me an invoice",
    },
    validationSchema: sessionModalValidate,
    onSubmit: async (values) => {
      if (error) {
        return;
      } else if([3,4].includes(data?.user?.role_id) && Number(values.count) > data?.organization?.available_seat){
        setError("Your organization doesn't have enough seats. Please contact your administrator");
        return;
      }
      setIsEdit(true);
      const Invoice = "Send me an invoice";
      const purchase = "Purchase order";
      const themselves = "Participants will pay for themselves";
      const addSessionDts = {
        name: values.name.trim(),
        organization_id: data?.organization.id,
        no_of_participant: values.count,
        session_date: moment(values.date).format("MM/DD/YYYY"),
        amount:
          values?.payment === themselves
            ? Number(values?.amount !== 0 ? values?.amount.split(" ")[0] : 0)
            : values?.amount,
        facilitator: parseInt(values.facilitator),
        payment_method:
          values.payment === Invoice ? 0 : values.payment === purchase ? 1 : 2,
        is_check_mark: isChecked,
        co_facilitator: parseInt(values?.co_facilitator)
          ? parseInt(values?.co_facilitator)
          : null,
      };

      try {
        if (id === 1) {
          addSessionDts.id = session?.id;
          addSessionDts["organization_sheet"] = addSessionDts?.is_check_mark ? Number(values.count) : 0;
          delete addSessionDts?.payment_method;
          delete addSessionDts?.is_check_mark;
          await updateSessionMutation.mutateAsync(addSessionDts);
          // if(values.count < session.no_of_participant){
          //   await removeSingleParticipant.mutateAsync({id: session?.id, org_id:session?.organization_id, remove_count: session.no_of_participant - values.count });
          // }
          getTeam.refetch();
          getSession.refetch();
          participantData.refetch();
          hide();
        } else {
          await addSessionMutation.mutateAsync(addSessionDts);
          refetch();
          if(!!session_id){
            navigate("/session");
          }
          hide();
        }
      } catch (error) {}
    },
  });

  const handleCountChange = (event) => {
    setIsEdit(false);
    let newValue = parseInt(event.target.value);
    if (id === 1 && newValue < participantData?.data?.length) {
      setError(constant?.editSessionError);
    } else if (isNaN(newValue)) {
      newValue = 0;
      setError(constant?.editSessionError);
    } else if (newValue < 4) {
      setError(constant?.editSessionError);
    } else if (newValue > 100) {
      newValue = 100;
      setError(constant?.editSessionError);
    } else {
      setError("");
    }
    formik.setFieldValue("count", newValue.toString());
    formik.handleChange(event.toString());
  };

  useEffect(() => {
    if (id === 1) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newAmount = 0;
    let count = formik.values.count;
    let availableSeat = data?.organization?.available_seat;
    if (
      formik.values.payment === "Send me an invoice" ||
      formik.values.payment === "Purchase order"
    ) {
      if (isChecked) {
        if (availableSeat < count) {
          newAmount =
            data?.organization?.organization_type_id?.fee *
            (count - availableSeat);
          setAvailableSeat(id === 1 ? count-session.no_of_participant : 0);
        } else if (availableSeat < 4) {
          formik.setFieldValue("count", 4);
          newAmount =
            data?.organization?.organization_type_id?.fee *
            (count - availableSeat);
          setAvailableSeat(availableSeat - count);
        } else {
          newAmount = 0;
          setAvailableSeat(availableSeat - count);
        }
      } else {
        newAmount = data?.organization?.organization_type_id?.fee * count;
        setAvailableSeat(availableSeat);
      }
    } else {
      if (
        isChecked &&
        formik.values.payment === "Participants will pay for themselves"
      ) {
        const availableSeat = data?.organization?.available_seat;
        if (availableSeat < count) {
          setAvailableSeat(0);
          newAmount =
            (
              (data?.organization?.organization_type_id?.fee *
                (count - availableSeat)) /
              count
            ).toFixed(2) + " per participant";
        } else if (availableSeat < 4) {
          formik.setFieldValue("count", 4);
          newAmount =
            (
              (data?.organization?.organization_type_id?.fee *
                (count - availableSeat)) /
              count
            ).toFixed(2) + " per participant";
          setAvailableSeat(availableSeat - count);
        } else {
          newAmount = 0;
          setAvailableSeat(availableSeat - count);
        }
      } else {
        newAmount =
          data?.organization?.organization_type_id?.fee + " per participant";
        setAvailableSeat(availableSeat);
      }
    }

    if (id === 0) {
      formik.setFieldValue("amount", newAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.payment,
    formik.values.count,
    availableSeat,
    data?.organization?.available_seat,
    data?.organization?.fee,
    isChecked,
  ]);

  const handleCancel = () => {
    setShowCancel(!showCancel);
  };

  const handleFacilitatorChange = (e) => {
    // setIsEdit(false);
    // setCoFacilitatorDisabled(false);
    formik.setFieldValue(e.target.name, e.target.value);
    // formik.setFieldValue("co_facilitator", "");
  };

	const handleFacilitatorDisplay = (data, isFac) => {
		// return organizationUser?.data?.organization_user?.map((item, index) => {
		//   const isDifferentId = item?.id !== Number(data);

		//   if (isDifferentId || isFac) {
		//     return (
		//       <option key={index} value={parseInt(item?.id)}>
		//         {item.first_name}
		//       </option>
		//     );
		//   }
		//   return null;
		// });
		return organizationUser?.data?.organization_user?.map((item, index) => {
			return (
				<option key={index} value={parseInt(item?.id)}>
					{item.first_name}
				</option>
			);
		});
	};

	const handleProducerDisplay = () => {
		return organizationUser?.data?.producer_user?.map((item, index) => {
			return (
				<option key={index} value={parseInt(item?.id)}>
					{item.first_name}
				</option>
			);
		});
	};

  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        size="xl"
        centered
        className={showCancel ? "d-none" : ""}
        backdrop="static"
      >
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>
            {id === 1 ? "Edit Session Details" : "Schedule a New Session"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-10 pt-10 px-lg-20">
          {id === 0 ? (
            <p className="text-center mb-10">{modal?.sessionModalBodyText}</p>
          ) : (
            <p className="text-center mb-10">
              {modal?.sessionModalEditDetails}
            </p>
          )}
          <Form onSubmit={formik?.handleSubmit}>
            <Row className="gx-lg-20 gy-6">
              <Col lg={6}>
                <div>
                  <label className="form-label" htmlFor="">
                    {modal?.sessionModalLableText}
                  </label>
                  <FormControl
                    onChange={(e) => {
                      setIsEdit(false);
                      formik.handleChange(e);
                    }}
                    value={formik.values.name}
                    disabled={id === 1 && session.is_open_game_link}
                    name="name"
                    id=""
                    placeholder=""
                  />
                  {formik.submitCount > 0 && formik.errors.name && (
                    <div className="text-danger text-13">
                      {formik.errors.name}
                    </div>
                  )}
                </div>
                <div className="mt-6">
                  <label className="form-label" htmlFor="">
                    {modal?.sessionModalSessionDate}
                  </label>
                  <div className="session-datepicker">
                    <DatePicker
                      name="date"
                      className="form-control"
                      selected={
                        formik.values.date
                          ? moment(formik.values.date).toDate()
                          : null
                      }
                      onChange={(date) => {
                        setIsEdit(false);
                        formik.setFieldValue("date", date);
                      }}
                      dateFormat="MMMM dd, yyyy"
                      placeholderText="Select date"
                      showIcon
                      minDate={new Date()}
                      disabled={id === 1 && session.is_open_game_link}
                    />
                    {formik.submitCount > 0 && formik.errors.date && (
                      <div className="text-danger text-13">
                        {formik.errors.date}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  <label className="form-label" htmlFor="">
                    {constant?.facilitator}
                  </label>
                  <FormSelect
                    placeholder="facilitator"
                    name="facilitator"
                    as="select"
                    value={formik.values.facilitator}
                    onChange={(e) => handleFacilitatorChange(e)}
                    disabled={id === 1 && session.is_open_game_link}
                  >
                    <option value="" disabled hidden>
                      {modal?.sessionModalSelectFacilitator}
                    </option>
                    {handleFacilitatorDisplay(formik.values.facilitator, true)}
                  </FormSelect>
                  {formik.submitCount > 0 && formik.errors.facilitator && (
                    <div className="text-danger text-13">
                      {formik.errors.facilitator}
                    </div>
                  )}
                </div>
                <div className="mt-6">
                  <label className="form-label" htmlFor="">
                    {constant?.producer} (recommended)
                  </label>
                  <FormSelect
                    placeholder="co_facilitator"
                    value={formik.values.co_facilitator}
                    onChange={(e) => {
                      setIsEdit(false);
                      formik.handleChange(e);
                    }}
                    name="co_facilitator"
                    as="select"
										disabled={
											((id === 1 || id === 0) && session.is_open_game_link) ||
											organizationUser?.data?.producer_user.length === 0
                    }
                  >
                    <option value="" disabled hidden>
                      {modal?.sessionModalSelectCoFacilitator}
                    </option>
										{/* {handleFacilitatorDisplay(formik.values.facilitator)} */}
										{handleProducerDisplay()}
									</FormSelect>
                  {formik.submitCount > 0 && formik.errors.co_facilitator && (
                    <div className="text-danger text-13">
                      {formik.errors.co_facilitator}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <label className="form-label" htmlFor="">
                    {modal?.sessionModalNumberOfParticipants}
                  </label>
                  <div className="app hstack gap-2">
                    <Button
                      variant="outline-grayish-blue"
                      className="px-3 py-2  text-primary rounded-2"
                      onClick={() => decrement()}
                      disabled={[0,1].includes(id) && (formik.values.count == 4 || session.is_open_game_link)}
                    >
                      <BsDashLg />
                    </Button>
                    <FormControl
                      value={formik.values.count}
                      onChange={handleCountChange}
                      className="w-20 text-center"
                      name="count"
                      disabled={id === 1 && session.is_open_game_link}
                    />
                    <Button
                      variant="outline-grayish-blue"
                      className="px-3 py-2 text-primary rounded-2"
                      onClick={() => increment(formik)}
                      disabled={id === 1 && session.is_open_game_link}
                    >
                      <BsPlus />
                    </Button>
                  </div>
                  {error && (
                    <p className="text-danger text-13">{error}</p>
                  )}
                </div>
                {([1, 2].includes(data?.user?.role_id)) && (
                  <div className="mt-6">
                    <label className="form-label" htmlFor="">
                      {modal?.sessionModalAmountDue}
                    </label>
                    <FormControl
                      onChange={formik.handleChange}
                      disabled
                      value={"$" + formik.values.amount}
                      className="w-48 mb-3 placeholder-dark-50 text-number"
                      name="amount"
                      id=""
                      type="text"
                    />

                    {formik.submitCount > 0 && formik.errors.amount && (
                      <div className="text-danger text-13">
                        {formik.errors.amount}
                      </div>
                    )}
                    <Form.Check type={"checkbox"}>
                      <Form.Check.Input
                        onChange={handleChange}
                        checked={isChecked}
                        className="me-2"
                        type="checkbox"
                        disabled={!([1, 2].includes(data?.user?.role_id)) || (id === 1 || availableSeat === 0)}
                        value={availableSeat}
                      />
                      <Form.Check.Label
                        className={`font-regular ${
                          availableSeat !== 0
                            ? "text-primary"
                            : "text-black opacity-50"
                        }`}
                      >
                        {modal?.sessionModalAvailableSeats}
                        {([1, 2].includes(data?.user?.role_id)) && (
                          <>
                            {" "}({availableSeat +" "+ modal?.sessionModalRemaining})
                          </>
                        )}
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                )}
                {([1, 2].includes(data?.user?.role_id)) && formik.values.amount !== 0 && (
                  <div className="mt-6">
                    <label className="form-label" htmlFor="">
                      {modal?.sessionModalPaymentMethod}
                    </label>
                    <div role="group" aria-labelledby="my-radio-group">
                      {check?.map((item, index) => {
                        return (
                          <Form.Check
                            key={index}
                            type={"checkbox"}
                            className="payment-method-options"
                          >
                            <Form.Check.Input
                              checked={formik.values.payment === item.payment}
                              name="payment"
                              value={item.payment}
                              onChange={(e) => {
                                setIsEdit(false);
                                formik.handleChange(e);
                              }}
                              type="radio"
                              id={`check${index}`}
                              className="me-2"
                              disabled={id === 1}
                            />
                            <Form.Check.Label
                              className="text-corn-blue font-regular text-base text-roboto lh-normal"
                              htmlFor={`check${index}`}
                            >
                              {item.payment}
                            </Form.Check.Label>
                            <p className="payment-method-description">
                              {item.desc}
                            </p>
                          </Form.Check>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Col>
              <Col lg={12} className="mt-10 text-center">
                <Button
                  variant="secondary"
                  onClick={formik.handleSubmit}
                  size="lg"
                  className="font-semibold px-sm-20"
                  disabled={isEdit}
                >
                  {id === 1 ? "Save" : "Schedule"}
                </Button>
                {id === 1 && !session.is_open_game_link && (
                  <div className="mt-3">
                    <Link
                      onClick={handleCancel}
                      className="text-danger text-no-decoration"
                    >
                      {modal?.cancleSessionModal}
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {showCancel && (
        <CancelModal
          show={showCancel}
          session={session}
          organization_sheet={data?.organization?.available_seat}
          hide={() => handleCancel()}
        />
      )}
    </>
  );
}
export default SessionModal;
