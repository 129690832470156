import { React, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  FormControl,
  Form,
} from "react-bootstrap";
import { useFormik } from "formik";
import { Logo } from "@/assets/images/index";
import { Link, useNavigate } from "react-router-dom";
import { loginValidate } from "@/utils/validation";
import { supabase } from "@/config/supabase";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { constant } from "@/utils/constant";

const Login = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidate,
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = await supabase.auth.signInWithPassword(values);
        if (data.data?.user !== null) {
          localStorage.setItem("token", data.data?.session?.access_token);
          localStorage.setItem("userId", data.data?.user?.id);
          navigate("/session");
        } else {
          setLoginError("Incorrect email or password");
        }
      } catch (error) {
        setLoginError(error.response?.data?.error);
        resetForm({
          values: {
            ...values,
            email: "",
            password: "",
          },
        });
      }
    },
  });

  const handleSignInClick = () => {
    setLoginError("");
    formik.handleSubmit();
  };

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    formik.setFieldValue(fieldName, value);
    setLoginError("");
  };
  return (
    <section className="bg-primary min-h-screen hstack ">
      <Container>
        <Row>
          <Col lg={4} md={6} className="mx-auto">
            <div className="text-center">
              <Image src={Logo} className="h-10" />
              <p className="text-light mt-4">
              {constant?.virtual}
                <span className="font-bold ms-1">{constant?.fridayNight}</span>
              </p>
            </div>
            <form onSubmit={formik.handleSubmit} className="mt-10 form mx-auto">
              <div className="mt-6">
                <label className="form-label text-light" htmlFor="email">
                  {constant?.email}
                </label>
                <FormControl
                  placeholder=""
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => handleChange(e, "email")}
                />
                {formik.submitCount > 0 && formik.errors.email && (
                  <div className="text-danger">{formik.errors.email}</div>
                )}
              </div>
              <div className="mt-6">
                <label className="form-label text-light" htmlFor="lastName">
                  {constant?.password}
                </label>
                <Form.Group className="vstack justify-content-center">
                  <div className="position-relative">
                    <FormControl
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.password}
                      onChange={(e) => handleChange(e, "password")}
                      placeholder=""
                    />
                    {formik.values.password && (
                      <span
                        className="position-absolute end-5 top-1 text-primary cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                      </span>
                    )}
                  </div>
                  {formik.submitCount > 0 && formik.errors.password && (
                    <div className="text-danger">{formik.errors.password}</div>
                  )}
                  {loginError && (
                    <div className="text-danger">{loginError}</div>
                  )}
                </Form.Group>
              </div>
              <div className="px-2">
                <Button
                  className="w-full mt-10 mb-3 btn btn-secondary btn-lg"
                  type="submit"
                  onClick={handleSignInClick}
                >
                  {constant?.signIn}
                </Button>
                <Link
                  to={`/forgot-password`}
                  className="text-light hstack justify-content-center text-no-decoration"
                >
                  {constant?.forgotPassword}
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Login;
