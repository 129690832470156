import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { modal } from "@/utils/constant";

function JoinModal({ hide, show, facilitatorData, facilitatorId, teamId }) {
  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title> {modal?.joinModalTitalText}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p>{modal?.joinModalBodyText}</p>
          <Row className="mt-8">
            <Col>
              <Button
                variant="secondary"
                onClick={hide}
                href={`${process.env.REACT_APP_GAME_URL}/join?participant_id=${facilitatorData.participant_id?.id}&team_id=${teamId}&session_id=${facilitatorData.session_id}&join_as=${facilitatorId}&department_id=${facilitatorData?.role_id}`}
                className="font-semibold w-full text-no-decoration"
                target="_blank"
                rel="noreferrer"
              >
                {modal?.joinModalButtonText}
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
                onClick={hide}
              >
                {modal?.cancle}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default JoinModal;
