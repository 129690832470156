import React from "react";
import { Button } from "react-bootstrap";

function CopyButton({ session, guestParticipants }) {
  const [copied, setCopied] = React.useState(false);

  const copyPaymentUrl = () => {
    if (!guestParticipants) {
      const el = document.createElement("input");
      el.value = session?.payment_link !== null ? session?.payment_link : "";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };
  return (
    <Button variant="success" size="sm" onClick={() => copyPaymentUrl()}>
      <div
        style={{
          display: "flex",
        }}
      >
        <Clippy copied={copied} />
        <Check
          visibility={copied ? "visible" : "hidden"}
          style={{
            color: "white",
            display: copied ? "block" : "none",
            strokeDasharray: 50,
            transition: "all 300ms ease-in-out",
          }}
        />
      </div>
    </Button>
  );
}

function Clippy(props) {
  return (
    <p
      style={{
        color: "white",
        transition: "all 300ms ease-in-out",
      }}
    >
      {!props?.copied ? "Copy" : ""}
    </p>
  );
}

function Check(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={props?.css}
      {...props}
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
}
export default CopyButton;