import React from "react";
import { Card } from "react-bootstrap";
import { Placeholder } from "@/assets/images";
import { BsFillCheckCircleFill, BsXCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useGetParticipant } from "@/customHooks/index";
import moment from "moment";
import { encrypt } from "@/utils/crypto";
import { constant } from "@/utils/constant";

function SessionCard({ data }) {
  const participantData = useGetParticipant(data?.id);
  const participants = participantData?.data || [];

  // Calculate unassign participant.
  const totalLengthWithNull = participants?.reduce((total, item) => {
    if (item.team_participant == null) {
      return total;
    }
    return total + item.team_participant.length;
  }, 0);
  const unassigned = data?.no_of_participant - totalLengthWithNull;

  const sessionDate = moment(data?.session_date);
  const currentDate = moment();
  const isToday = sessionDate.isSame(currentDate, "day");
  const unpaid = participants.filter((item) => item.is_paid === 0);
  const totalUnpaid =
    unpaid.length + (data?.no_of_participant || 0) - participants.length;
  const paymentMethod = data?.payment_method;

  const renderPaymentStatus = () => {
    let icon, statusText;

    const commonText = data?.amount === 0 ? constant?.paidWithSeats : "";

    switch (paymentMethod) {
      case 1:
        if (data?.amount === 0 || totalUnpaid === 0) {
          icon = <BsFillCheckCircleFill className="me-2" />;
          statusText = commonText || "PO accepted";
        } else {
          icon = <BsXCircle className="me-2" />;
          statusText = constant?.waitingforPO;
        }
        break;

      case 2:
        if (data?.amount === 0 || totalUnpaid === 0) {
          icon = <BsFillCheckCircleFill className="me-2" />;
          statusText = commonText || constant?.AllParticipantsPaid;
        } else {
          icon = <BsXCircle className="me-2" />;
          statusText = `${totalUnpaid} unpaid participant${
            totalUnpaid === 1 ? "" : "s"
          }`;
        }
        break;

      case 0:
        if (data?.amount === 0 || totalUnpaid === 0) {
          icon = <BsFillCheckCircleFill className="me-2" />;
          statusText = commonText || "Invoice paid";
        } else {
          icon = <BsXCircle className="me-2" />;
          statusText = "Invoice unpaid";
        }
        break;

      default:
        icon = null;
        statusText = "";
    }

    return (
      <>
        {icon}
        {statusText}
      </>
    );
  };

  return (
    <Card className="h-full">
      <Card.Img variant="top" src={Placeholder} />
      <Card.Body className="vstack pt-2">
        <Card.Title className="mb-0 text-futura text-xl font-semibold text-primary">
          {data?.name}
        </Card.Title>
        <Card.Text className="text-sm">
          {moment(data?.session_date).format("LL")} {isToday && "(Today)"} |
          {data?.no_of_participant} {constant?.member}
        </Card.Text>
        <Card.Text className="text-sm">
          <span className="me-1">{constant?.facilitator}:</span>
          {`${data?.facilitator?.first_name || "-----"} ${
            data?.facilitator?.last_name || "-----"
          }`}
        </Card.Text>
        <Card.Text className="text-sm mb-2">
          <span className="me-1">{constant?.producer}:</span>
          {`${data?.co_facilitator?.first_name || "None"} ${
            data?.co_facilitator?.last_name || ""
          }`}
        </Card.Text>
        <div className="mt-auto">
          {unassigned === 0 ? (
            <p className="text-success hstack mt-auto">
              <BsFillCheckCircleFill className="me-2" />{" "}
              {constant?.participantAssigned}
            </p>
          ) : (
            <p className="text-danger hstack">
              {!isNaN(unassigned) ? (
                <>
                  <BsXCircle className="me-2" /> {unassigned}{" "}
                  {constant?.unassigned} {constant?.member}
                </>
              ) : (
                ""
              )}
            </p>
          )}
          <p
            className={
              totalUnpaid === 0 || data?.amount === 0
                ? "text-success mb-4 hstack"
                : "text-danger mb-4 hstack"
            }
          >
            {renderPaymentStatus()}
          </p>
        </div>
        <Link
          to={`/participants/${encrypt(String(data?.id))}`}
          className="w-full btn btn-outline-primary"
        >
          {constant?.manageSession}
        </Link>
      </Card.Body>
    </Card>
  );
}

export default SessionCard;
